import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormButton } from "../../components/forms/FormButton";
import { FormHeader } from "../../components/forms/FormHeader";
import { FormRow } from "../../components/forms/FormRow";
import { NotificationService } from "../../components/notification/NotificationService";
import { SessionContext, isOniqDev, isOniqEmployee, isWorkshopAccount } from "../../contexts/SessionContext";
import Global from "../../Global";
import i18n from "../../i18n";
import { Api } from "../../api/Api";
import { isObject } from "lodash";
import { Spotlight } from "../../components/spotlight/Spotlight";
import { BottleneckFactorWeights } from "../../models/Project";
import { sanitizeString } from "../../utils/Utils";

type FormData = {
    name: string;
    description: string;
    deleteValidation: string;
    customerKpis: string;
    bottleneckFactorWeights: BottleneckFactorWeights;
}

export const defaultRcaIndicatorsWeights: BottleneckFactorWeights = {
    "throughputTime": 1,
    "stockDifference": 1,
    "throughputDifference": 1,
    "cycleTime": 1,
    "utilization": 1,
    "stockRange": 1,
    "availability": 1,
    "stockRangeDifference": 0,
    "utilizationRate": 0,
    "busyRatio": 0,
};

export function ProjectSettingsView() {
    const session = useContext(SessionContext);

    const isMyProject = session.project?.userId === session.user?.sub;

    const [isDeleting, setIsDeleting] = useState(false);
    const navigate = useNavigate();

    const [mayDelete, setMayDelete] = useState(false);

    const sanitizedProjectName = (session.project?.name ?? "undefined").toLowerCase();

    const { register, handleSubmit, reset, formState: { errors, isDirty } } = useForm<FormData>({
        defaultValues: {
            name: session.project?.name,
            description: session.project?.description,
            customerKpis: JSON.stringify(session.project?.settings?.customerKpis, undefined, 4),
            bottleneckFactorWeights: session.project?.settings?.bottleneckFactorWeights ?? defaultRcaIndicatorsWeights,
        }
    });

    const isOniqUser = isOniqEmployee(session);
    const isOniqDevUser = isOniqDev(session);
    const isWorkshopAccountUser = isWorkshopAccount(session);

    return <div className="next">
        <form onSubmit={handleSubmit(updateProject)} autoComplete="off">
            <FormHeader title="settings.projectSettings" description="settings.projectSettingsDesc">
                <div className="flexGap">
                    <FormButton disabled={!isDirty} className="formButtonSecondary" onClick={() => {
                        reset({
                            name: session.project?.name ?? "",
                            description: session.project?.description ?? "",
                            deleteValidation: "",
                        });
                        setMayDelete(false);
                    }}>{i18n.t("common.cancel")}</FormButton>
                    <FormButton disabled={!isDirty} className="formButtonPrimary">{i18n.t("filters.save")}</FormButton>
                </div>
            </FormHeader>
            <FormRow title="identifyColumns.projectName" description="settings.nameDesc" isMandatory={true}>
                <input
                    type="text"
                    className="inputFillRow"
                    disabled={!isMyProject}
                    {...register("name", { required: true, minLength: 1 })}
                    placeholder={i18n.t("identifyColumns.projectName").toString()} />
                {errors.name?.type !== undefined && <div className="formError">
                    {i18n.t("forms.textMissing")}
                </div>}
                {!isMyProject && <div className="formError">
                    {i18n.t("forms.onlyProjectOwnerMayDoThis")}
                </div>}
            </FormRow>
            <hr />

            <FormRow title="identifyColumns.projectDescription" description="settings.projectDescriptionDesc">
                <textarea className="inputFillRow" {...register("description")} disabled={!isMyProject} />
                {!isMyProject && <div className="formError">
                    {i18n.t("forms.onlyProjectOwnerMayDoThis")}
                </div>}
            </FormRow>
            <hr />

            {(isOniqDevUser || isWorkshopAccountUser) && <>
                <FormRow title="rca.bottleneck.indicators.bottleneckFactors">
                    {Object.keys(defaultRcaIndicatorsWeights).map(key => (
                        <div key={key}>
                            <h3>{i18n.t(`rca.bottleneck.indicators.${key}`)}</h3>
                            <input
                                type="number"
                                className="input mbs"
                                min="0"
                                max="1"
                                step="0.1"
                                {...register(`bottleneckFactorWeights.${key}`, { required: false, valueAsNumber: true })}
                                placeholder={"1.0"} />
                        </div>
                    ))}
                </FormRow>
                <hr />
            </>}


            {isOniqUser && <>
                <FormRow title="common.customerSpecificKpis" description="">
                    <Spotlight id="Settings-Customer-KPIs" />
                    <textarea className="inputFillRow" style={{ resize: "vertical", height: 300 }} {...register("customerKpis", {
                        validate: {
                            customerKpis: (value: string) => {
                                try {
                                    if (value.trim().length === 0)
                                        return true;

                                    const result = JSON.parse(value);
                                    if (!result || !isObject(result))
                                        return false;

                                    return true;
                                } catch (e) {
                                    return false;
                                }
                            }
                        }
                    })} />
                    {errors.customerKpis && <div className="formError">
                        {i18n.t("forms.invalidJson")}
                    </div>}
                </FormRow>
                <hr />
            </>}

            {isMyProject && <FormRow title="settings.deleteProject">
                <div className="text" dangerouslySetInnerHTML={{ __html: i18n.t("settings.deleteProjectDesc", { projectName: sanitizeString(session.project?.name) }) }} />

                <div className="flexGap">
                    <input
                        type="text"
                        className="flexGrow"
                        title={i18n.t("settings.confirmProjectDeletion", { projectName: session.project?.name }).toString()}
                        placeholder={i18n.t("settings.confirmProjectDeletion", { projectName: session.project?.name }).toString()}
                        {...register("deleteValidation")}
                        onChange={e => {
                            const v = e.target.value.toLowerCase();
                            setMayDelete(v === sanitizedProjectName);
                        }} />
                    <FormButton disabled={!mayDelete} spinning={isDeleting} className="formButtonDanger noWrap alignCenter" onClick={deleteProject}>
                        {i18n.t("settings.deleteProject").toString()}
                    </FormButton>
                </div>
            </FormRow>}
        </form>
    </div>;

    async function updateProject(formData: FormData) {
        if (!session.project)
            return;

        const customerKpis = isOniqUser ?
            (formData.customerKpis.trim().length === 0 ? undefined : JSON.parse(formData.customerKpis)) : session.project?.settings?.customerKpis;

        const bottleneckFactorWeights = isOniqUser ? formData.bottleneckFactorWeights : undefined;

        const project = {
            ...session.project,
            name: formData.name,
            description: formData.description,
            settings: {
                ...session.project.settings,
                customerKpis,
                bottleneckFactorWeights: bottleneckFactorWeights
            }
        };

        await Api.updateProject(project);

        session.set({
            project,
        });

        reset(project);

        NotificationService.add({
            className: "light success-accent",
            summary: "settings.projectUpdated",
            message: "",
            autoCloseDelay: Global.defaultNotificationDelay,
        });
    }

    async function deleteProject() {
        if (!session.projectId)
            return;

        setIsDeleting(true);
        await Api.deleteProject(session.projectId);


        const projectName = session.project?.name ?? "";
        NotificationService.add({
            className: "light success-accent",
            summary: i18n.t("settings.projectDeleted", { projectName }),
            message: i18n.t("settings.projectDeletedMessage", { projectName }) ?? "",
            autoCloseDelay: Global.defaultNotificationDelay,
            icon: "radix-trash",
        });

        session.set({
            projectId: "",
            project: undefined,
        });

        navigate("/projects");
    }
}
