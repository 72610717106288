import Pako from "pako";
import qs from "qs";
import { ISidePanel } from "./components/side-panel/SidePanel";
import { DfgLocationDescriptor, SettingsType } from "./contexts/SettingsContext";
import { DeepPartial, ObjectMerger } from "./utils/ObjectMerger";
import { getRouteFromLocation } from "./Routing";
import { matchPath } from "react-router";

const apiEndpoint = getConfigValue(process.env.REACT_APP_API_ENDPOINT ?? process.env.STORYBOOK_API_ENDPOINT, "__REACT_APP_API_ENDPOINT__", "http://localhost:8000");
const apiEndpointThinker = getConfigValue(process.env.REACT_APP_API_ENDPOINT_THINKER ?? process.env.STORYBOOK_API_ENDPOINT_THINKER, "__REACT_APP_API_ENDPOINT_THINKER__", apiEndpoint);

const isNodeContext = process.env.IS_NODE_CONTEXT ?? process.env.STORYBOOK_IS_NODE_CONTEXT;
const envToken = isNodeContext !== undefined ? (process.env.UPDATE_TOKEN ?? process.env.STORYBOOK_UPDATE_TOKEN ?? "")?.replace("Bearer ", "") : undefined;


/**
 * This class holds global, immutable settings
 */
export default class Global {
    static storybookPreferences: DeepPartial<SettingsType> | undefined = undefined;

    static readonly auth0 = {
        domain: getConfigValue(process.env.REACT_APP_AUTH0_DOMAIN, "__REACT_APP_AUTH0_DOMAIN__", "oniq-staging-env.eu.auth0.com"),
        clientId: getConfigValue(process.env.REACT_APP_AUTH0_CLIENT_ID, "__REACT_APP_AUTH0_CLIENT_ID__", "FK4PDwoqqX9IEVqkyWsWLCWpwZ7ZkuOo"),
        audience: getConfigValue(process.env.REACT_APP_AUTH0_AUDIENCE, undefined, "api://oniq"),
    };

    static readonly apiEndpoint = apiEndpoint;
    static readonly apiEndpointThinker = apiEndpointThinker;
    static token: string | undefined = envToken;
    static readonly sentry = {
        // The dsn can be in the repo (public) as it is used in the browser
        // anyway. It cannot be used to read any information (just to send).
        dsn: "https://9729c0bf77fc4087a486496663b840ab@o919080.ingest.sentry.io/5863829",
        tracesSampleRate: 0.02,
        release: getConfigValue(process.env.REACT_APP_SENTRY_RELEASE, "__REACT_APP_SENTRY_RELEASE__", ""),
        environment: getConfigValue(process.env.REACT_APP_SENTRY_ENVIRONMENT, "__REACT_APP_SENTRY_ENVIRONMENT__", "development")
    };

    static readonly defaultNotificationDelay = 15000;
    static readonly shortNotificationDelay = 3000;

    static readonly isRunningJestTest = process.env.JEST_WORKER_ID !== undefined;

    static readonly isRunningStorybook = process.env.IS_STORYBOOK !== undefined || process.env.STORYBOOK !== undefined;

    static readonly isDesktop = (() => {
        const userAgent = navigator.userAgent.toLowerCase();

        // detecting ipads is tricky, as safari reports the same user agent as a mac
        const hasTouch = navigator.maxTouchPoints && navigator.maxTouchPoints >= 1;
        const isTablet = hasTouch && /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

        return !isTablet;
    })();

    static readonly isTouchEnabled = (() => {
        return "ontouchstart" in window || navigator.maxTouchPoints > 0 || (navigator as unknown as any).msMaxTouchPoints > 0;
    })();

    /**
     * Current reference to the side panel that is displayed on the right side of the screen
     */
    static sidePanelRef: React.RefObject<ISidePanel>;

    static projectLoadingSpinnerHasBeenShown = false;

    static locationDescriptor: DfgLocationDescriptor | undefined = undefined;

    /**
     * Default options to send along with any request. Sets the authorization bearer token
     * and JSON content type, see https://pbs.twimg.com/media/EvBSnuMXYAE6UO0.jpg
     */
    static get defaultRequestOptions() {
        return {
            headers: {
                "Authorization": "Bearer " + Global.token,
                "Content-Type": "application/json",
                "Accept": "application/json"
            },

            paramsSerializer: {
                serialize: (params: unknown) => {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            },
        };
    }

}

/**
 * Resolves a configuration value
 *
 * @param envValue - Value obtained from build time variable (this variable is ONLY set during build time, for example during docker build or when using npm run start)
 * @param placeholderValue - This value is a placeholder that is replaced by our build chain at runtime (in docker distribution, see entrypoint.sh for how replacement works). It is not replaced or used during development.
 * @param fallbackValue - If no env value is set and the placeholder value was not replaced or replaced with an empty value, we use this fallback value
 * @returns
 */
function getConfigValue(envValue: string | undefined, placeholderValue: string | undefined, fallbackValue: string) {
    if (envValue)
        return envValue.trim();
    if (placeholderValue && !placeholderValue.startsWith("__") && !placeholderValue.endsWith("__"))
        return placeholderValue.trim();
    return fallbackValue.trim();
}

/**
 * When hitting a product-related API, use this default limit to restrict the response to a reasonable size
 */
export const defaultProductLimit = 500;

/**
 * When hitting a case-related API, use this default limit to restrict the response to a reasonable size
 */
export const defaultCaseLimit = 500;

/**
 * When hitting the API, this amount of entities is a good default limit. However,
 * for cases, products and variants, we have specific limits (defaultProductLimit,
 * defaultCaseLimit, defaultVariantsLimit).
 */
export const defaultApiLimit = 500;

/**
 * When hitting a variant-related API, use this default limit to restrict the response to a reasonable size
 */
export const defaultVariantsLimit = 3000;

/**
 * Default number of bins used in histograms
 */
export const numDefaultBinCount = 40;

/**
 * Returns the settings object. Some properties might be page-specific (rather than)
 * tab-specific). So these are retrieved from a different local storage item.
 */
export function getPreferences(pathname?: string) {
    const path = pathname ?? window.location.pathname;
    const tabId = `settings${path}`;
    const pageId = getPageId(path) ?? "";
    const tabJson = (Global.isRunningStorybook || Global.isRunningJestTest) ? JSON.stringify(Global.storybookPreferences) : localStorage.getItem(tabId);
    const pageJson = (Global.isRunningStorybook || Global.isRunningJestTest) ? JSON.stringify(Global.storybookPreferences) : localStorage.getItem(pageId) ?? "{}";

    if (!tabJson && !pageJson)
        return undefined;

    const tab = decodePrefs(tabJson) ?? {};
    const page = decodePrefs(pageJson) ?? {};

    const temp = ObjectMerger.mergeObject(page, tab, true);
    return (Object.getOwnPropertyNames(temp).length > 0) ? temp : undefined;
}

function getPageId(path: string) {
    const { route, dimension } = getRouteFromLocation(path);
    if (!route) {
        if (!Global.isRunningJestTest && !Global.isRunningStorybook)
            console.warn("Could not match route", path);
        return undefined;
    }

    const params = matchPath(route, path);
    const projectId = params?.params.projectId;
    const pagePath = route.split("/").map(r => {
        if (r === ":projectId")
            return projectId;

        if (r.startsWith(":") || r === dimension)
            return "";

        return r;
    }).join("/");
    return `settings${pagePath}`;
}

function encodePrefs(strippedSettings: DeepPartial<SettingsType>) {
    const binary = Pako.deflate(JSON.stringify(strippedSettings), {
        level: 9, // best compression
    });

    return window.btoa(String.fromCharCode(...binary));
}

function decodePrefs(json: string | undefined | null) {
    if (!json)
        return undefined;

    // For backwards compatability, if the format is json and not base64, parse the json directly
    if (json?.[0] === "{")
        try {
            return JSON.parse(json) as DeepPartial<SettingsType> | undefined;
        } catch {
            return undefined;
        }

    try {
        const binary = window.atob(json ?? "").split("").map(c => c.charCodeAt(0));
        const buffer = new Uint8Array(binary);
        const inflatedJson = Pako.inflate(buffer, {
            to: "string"
        });

        return JSON.parse(inflatedJson) as Partial<SettingsType>;
    } catch {
        return undefined;
    }
}

export function setPreferences(settings: SettingsType, pathname?: string) {
    if (Global.isRunningStorybook)
        return;

    // This stores data for each tab
    const tabId = `settings${pathname ?? window.location.pathname}`;

    // The following properties will be stored per tab.
    const perTabData: DeepPartial<SettingsType> = {
        gantt: {
            caseGanttSettings: settings.gantt.caseGanttSettings,
        },
        graph: settings.graph,
        kpiMatrix: settings.kpiMatrix,
        orderTracking: settings.orderTracking,
        quantity: settings.quantity,
        supplyChain: settings.supplyChain,
        kpi: {
            statistic: settings.kpi.statistic,
            analyzedValue: settings.kpi.analyzedValue,
            highlightDeviations: settings.kpi.highlightDeviations,
            relativeToThroughputTime: settings.kpi.relativeToThroughputTime,
            selectedKpi: settings.kpi.selectedKpi,
            showCustomerTakt: settings.kpi.showCustomerTakt,
        },
    };
    localStorage.setItem(tabId, encodePrefs(perTabData));

    // Everything here is stored on per navigation element basis (e.g. value stream, products...)
    const pageId = getPageId(pathname ?? window.location.pathname);
    const perPageData: DeepPartial<SettingsType> = {
        gantt: {
            sortOrder: settings.gantt.sortOrder,
        },
        kpi: {
            timeScale: settings.kpi.timeScale,
            aggregation: settings.kpi.aggregation,
            comparisons: settings.kpi.comparisons,
            sortBy: settings.kpi.sortBy,
            sortOrder: settings.kpi.sortOrder,
        },
        groupingKey: settings.groupingKey,
    };

    if (pageId)
        localStorage.setItem(pageId, encodePrefs(perPageData));
}

export function openGlossary(locale: string | undefined, word?: string) {
    if (!locale)
        return;

    const url = `./glossary/${locale === "jp" ? "en" : locale}.html${word ? "#" + word : ""}`;
    const absoluteUrl = new URL(url, window.location.origin);
    window.open(absoluteUrl, "glossary", "height=600,width=900,popup=true,noopener");
}