import { useAuth0, withAuth0 } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { createHash } from "crypto";
import i18next from "i18next";
import jwtDecode, { JwtPayload } from "jwt-decode";
import React, { useContext, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { md5 } from "js-md5";
import { Userpilot } from "userpilot";
import { ApiErrorContextType, GetRootCauseAnalysisResponse } from "./models/ApiTypes";
import { getDimensionFromPathname } from "./components/dimension/Dimension";
import { Drift } from "./components/drift/Drift";
import { IModal } from "./components/modal/Modal";
import { NavGroup, NavGroupProps } from "./components/navigation/NavGroup";
import { NavItemProps } from "./components/navigation/NavItem";
import { Notification, NotificationProps } from "./components/notification/Notification";
import { NotificationHost } from "./components/notification/NotificationHost";
import { NotificationService } from "./components/notification/NotificationService";
import { SessionContext, SessionInstance, isOniqEmployee } from "./contexts/SessionContext";
import { SettingsContext, applySettingsHash, getRecentRcaByType, serializeSettings, updateRecentRcaByType } from "./contexts/SettingsContext";
import "./i18n";
import i18n, { numberFormatLocales, supportedLanguages } from "./i18n";
import { trackMatomoEvents } from "./utils/Matomo";
import AddProjectUpload from "./views/add-project-upload/AddProjectUpload";
import { Dashboard } from "./views/dashboard/Dashboard";
import CreateProject from "./views/identify-columns/CreateProject";
import EditOrderTrackingUpload from "./views/identify-columns/EditOrderTracking";
import EditPlan from "./views/identify-columns/EditPlan";
import EditProject from "./views/identify-columns/EditProject";
import { ChangePasswordModal } from "./views/modals/ChangePasswordModal";
import { SettingsModal } from "./views/modals/SettingsModal";
import UnauthorizedModal, { getUnauthorizedModalMessage } from "./views/modals/UnauthorizedModal";
import { CarbonKpisView } from "./views/nav-views/CarbonKpisView";
import { CarbonProcessView } from "./views/nav-views/CarbonProcessView";
import { OrderTrackingContextAndView } from "./views/nav-views/OrderTrackingView";
import { OutputKpisView } from "./views/nav-views/OutputKpisView";
import { OutputProcessView } from "./views/nav-views/OutputProcessView";
import { QualityKpisView } from "./views/nav-views/QualityKpisView";
import { QualityProcessView } from "./views/nav-views/QualityProcessView";
import { StockKpisView } from "./views/nav-views/StockKpisView";
import { StockProcessView } from "./views/nav-views/StockProcessView";
import { TimingsKpisView } from "./views/nav-views/TimingsKpisView";
import { TimingsProcessView } from "./views/nav-views/TimingsProcessView";
import AddOrderTrackingUpload from "./views/order-tracking/AddOrderTrackingUpload";
import AddPlan from "./views/planning/AddPlan";
import ProjectBrowser from "./views/project-browser/ProjectBrowser";
import ProjectLog from "./views/project-log/ProjectLog";
import { SettingsView } from "./views/settings/SettingsView";
import { KaizenValueStreamView } from "./views/nav-views/ValueStreamDiagramView";
import { RcaType } from "./contexts/ContextTypes";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { getFilterFriendlyName } from "./models/EventFilter";
import { FavoritesView } from "./views/favorites/FavoritesView";
import { RootCauseView } from "./views/rca/RootCauseView";
import { SetupAnalysisView } from "./views/nav-views/SetupAnalysisView";
import { getHash } from "./utils/Utils";
import { WorkplaceComparisonView } from "./views/nav-views/WorkplaceComparisonView";
import BottleneckView from "./views/rca/BottleneckView";
import Global from "./Global";
import { Api } from "./api/Api";
import { isArray, isEmpty } from "lodash";
import { GotoView } from "./views/goto/GotoView";
import { SupplyChainView } from "./views/supply-chain/SupplyChainView";
import { ValueSpinner } from "./components/value-spinner/ValueSpinner";
import Spinner from "./components/spinner/Spinner";
import { LossesView } from "./views/rca/LossesView";
import { CycleTimeView } from "./views/nav-views/CycleTimeView";
import AnalysesView from "./views/analyses/AnalysesView";
import { EquipmentComparisonView } from "./views/nav-views/EquipmentComparisonView";

Userpilot.initialize("NX-37sc33e2");

const tokenFetchInterval = 3 * 60 * 1000;
const apiFetchInterval = 5 * 1000;

const defaultPage = "/projects";

// Remember to update Routing.tsx as well when adding new routes!
// Needed to refactor that out due to circular dependencies
const routes = [{
    path: "/projects/:projectId/settings/:tabSlug",
    element: <SettingsView />,
}, {
    path: "/favorites",
    element: <FavoritesView />,
}, {
    path: "/projects",
    element: <ProjectBrowser />,
}, {
    path: "/projects/add-project-upload",
    element: <AddProjectUpload />,
}, {
    path: "/data-sources",
    element: <AddProjectUpload />,
}, {
    path: "/projects/create-project/:uploadId",
    element: <CreateProject />,
}, {
    path: "/projects/:projectId/timings/kpis/:tabSlug",
    element: <TimingsKpisView />,
}, {
    path: "/projects/:projectId/timings/process/:tabSlug",
    element: <TimingsProcessView />,
}, {
    path: "/projects/:projectId/output/kpis/:tabSlug",
    element: <OutputKpisView />,
}, {
    path: "/projects/:projectId/output/process/:tabSlug",
    element: <OutputProcessView />,
}, {
    path: "/projects/:projectId/quality/kpis/:tabSlug",
    element: <QualityKpisView />,
}, {
    path: "/projects/:projectId/quality/process/:tabSlug",
    element: <QualityProcessView />,
}, {
    path: "/projects/:projectId/carbon/kpis/:tabSlug",
    element: <CarbonKpisView />,
}, {
    path: "/projects/:projectId/carbon/process/:tabSlug",
    element: <CarbonProcessView />,
}, {
    path: "/projects/:projectId/order-tracking/:tabSlug",
    element: <OrderTrackingContextAndView />,
}, {
    path: "/projects/:projectId/edit",
    element: <EditProject />,
}, {
    path: "/projects/:projectId/add-plan",
    element: <AddPlan />,
}, {
    path: "/projects/:projectId/edit-plan",
    element: <EditPlan />,
}, {
    path: "/projects/:projectId/add-order-tracking",
    element: <AddOrderTrackingUpload />,
}, {
    path: "/projects/:projectId/edit-order-tracking",
    element: <EditOrderTrackingUpload />,
}, {
    path: "/projects/:projectId/dashboard",
    element: <Dashboard />,
}, {
    path: "/projects/:projectId/stock/process/:tabSlug",
    element: <StockProcessView />,
}, {
    path: "/projects/:projectId/stock/kpis/:tabSlug",
    element: <StockKpisView />,
}, {
    path: "/projects/:projectId/log",
    element: <ProjectLog />
}, {
    path: "/projects/:projectId/supply-chain/:tabSlug",
    element: <SupplyChainView />
}, {
    path: "/projects/:projectId/analyses",
    element: <AnalysesView />,
}, {
    path: "/projects/:projectId/analyses/value-stream/:tabSlug",
    element: <KaizenValueStreamView />
}, {
    path: "/projects/:projectId/analyses/cycle-time/:tabSlug",
    element: <CycleTimeView />
}, {
    path: "/projects/:projectId/analyses/rca/:tabSlug",
    element: <RootCauseView />
}, {
    path: "/projects/:projectId/analyses/setup/:tabSlug",
    element: <SetupAnalysisView />
}, {
    path: "/projects/:projectId/analyses/equipments/:tabSlug",
    element: <EquipmentComparisonView />
}, {
    path: "/projects/:projectId/analyses/workplaces/:tabSlug",
    element: <WorkplaceComparisonView />
},
{
    path: "/projects/:projectId/analyses/bottleneck/:tabSlug",
    element: <BottleneckView />
}, {
    path: "/projects/:projectId/analyses/losses/:tabSlug",
    element: <LossesView />
}, {
    path: "/welcome",
    element: <></>,
}, {
    path: "/goto/:favoriteId",
    element: <GotoView />,
}, {
    path: "*",
    element: <Navigate to={defaultPage} replace />,
}];

const App = () => {
    const auth0 = useAuth0();
    const session = useContext(SessionContext);
    SessionInstance.session = session;

    const settings = useContext(SettingsContext);

    const [isRedirected, setIsRedirected] = useState(false);
    const helloRef = useRef<string | undefined>((new URL(document.location.href)).searchParams.get("to"));    // If we're having a redirection URL, redirect to that.

    useEffect(() => {
        const handlePopState = () => {
            applySettingsHash(window.location.hash, settings);
            // Add your logic here
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    useEffect(() => {
        applySettingsHash(window.location.hash, settings);
    }, []);

    Api.onError = (apiError) => {
        session.set({
            apiError
        });

        if (apiError.isFaulty) {
            // Transient network error
            if (apiError.isRetryable)
                NotificationService.add({
                    id: "transient-api-error",
                    className: "light warning-accent",
                    icon: "radix-bell",
                    summary: "errorModal.networkError.title",
                    message: "errorModal.networkError.msg"
                });

            // Permanent network error
            if (!apiError.isRetryable && session.apiError.error && isOniqEmployee(session))
                NotificationService.add(getPermanentApiErrorNotification(apiError));
        }
    };

    Api.onRecovery = () => {
        if (session.apiError.isFaulty)
            session.set({
                apiError: {
                    isFaulty: false,
                    error: undefined,
                    isRetryable: true,
                }
            });

        NotificationService.hide("transient-api-error");
    };

    const fetchToken = async () => {
        let token = Global.token ?? await auth0.getAccessTokenSilently();
        const jwtPayload = jwtDecode<JwtPayload>(token);
        const exp = (jwtPayload.exp ?? 0) * 1000;
        const timeToExpiration = (exp - Date.now());
        const isTokenAlmostExpired = timeToExpiration < (tokenFetchInterval * 2);
        if (isTokenAlmostExpired) {
            // get new token in any case even if current token might still be
            // valid for a few seconds.
            token = await auth0.getAccessTokenSilently({ ignoreCache: true });
        }
        Global.token = token;
        return token;
    };

    // Initialize authentication
    useEffect(() => {
        if (!auth0.isAuthenticated)
            return;

        (async () => {
            const token = await fetchToken();
            // we added a custom claim in auth0 to provide the organization
            const organizationClaim = "https://iqa.oniq.com/organization";
            const isCustomerClaim = "https://iqa.oniq.com/is-customer";
            const jwtPayload = jwtDecode<JwtPayload & { permissions?: string[], [organizationClaim]?: string, [isCustomerClaim]?: boolean }>(token);
            const organization = jwtPayload[organizationClaim] || "";
            const isCustomer = jwtPayload[isCustomerClaim];
            const permissions = jwtPayload?.permissions || [];

            const locale = getLocale([localStorage.getItem("locale"), auth0.user?.locale, navigator.language], Object.keys(supportedLanguages));
            const numberFormatLocale = getLocale([localStorage.getItem("numberFormatLocale"), auth0.user?.locale, navigator.language], numberFormatLocales.map(l => l.locale));

            session.set({
                locale,
                numberFormatLocale,
                apiToken: token,
                user: {
                    hash: createHash("sha256").update(auth0.user?.sub ?? auth0.user?.email ?? auth0.user?.name ?? "").digest("hex"),
                    email: auth0.user?.email ?? "",
                    name: auth0.user?.name ?? "?",
                    picture: auth0.user?.picture ?? "",
                    sub: auth0.user?.sub ?? "",
                    permissions,
                    organization,
                    isCustomer,
                },
            });

            // Tell i18n about the new locale
            i18next.changeLanguage(locale);

            if (auth0.user?.sub && permissions.includes("iqa")) {
                Userpilot.identify(auth0.user?.sub,
                    {
                        name: organization, // we just add the organization info to also distinguish them in the user view
                        company: { id: organization },
                        locale_code: session.locale,
                        isCustomer: isCustomer,
                    });

                // For privacy reasons, only set the user for oniq users
                const isOniqUser = organization?.toLowerCase() === "oniq";
                if (isOniqUser)
                    Sentry.setUser({
                        // replace oauth2 in string so that sentry does not filter it out
                        id: auth0.user.sub.replace("google-oauth2|", "")
                    });
            }

            Sentry.setTag("organization", organization);

            // Ensure access token is still valid while the application is running.
            setInterval(() => {
                fetchToken();
            }, tokenFetchInterval);

        })();
    }, [auth0, auth0.isAuthenticated]);

    useEffect(() => {
        if (session.apiToken) {
            // Only redirect to deep link if...
            if (!!helloRef.current && // we have a deep link
                helloRef.current.indexOf("?to=") < 0 && // the deep link is not a redirect
                helloRef.current !== window.location.origin + "/") { // the deep link is not the root 
                window.location.href = helloRef.current;
            }
            else {
                // No redirect necessary, show the page
                setIsRedirected(true);

                // However, is the current route still the welcome page?
                // If so, redirect to the catch-all route.
                if (window.location.pathname === "/welcome")
                    history.replaceState(null, "", defaultPage);
            }

            return;
        }
    }, [
        helloRef.current,
        session.apiToken,
    ]);


    // If we aren't authenticated or authorized and have a
    // reason for this we want to show the user, show that.
    const unauthorizedModalMessage = getUnauthorizedModalMessage(auth0, session.user);
    if (unauthorizedModalMessage)
        return <UnauthorizedModal auth0={auth0}>{unauthorizedModalMessage}</UnauthorizedModal>;

    // If we're not authenticated for some other reason just return to the login page.
    if (!auth0.isLoading && !auth0.isAuthenticated)
        (async () => await auth0.loginWithRedirect())();

    // Finally we might just be waiting for the token to appear
    if (!session.apiToken) {
        return <div>Loading...</div>;
    }

    // This is the basic page layout. Displayed after initialization
    // is complete.
    if (isRedirected)
        return <Page />;

    return null;
};


export type PagePropsType = {
    user?: User
};

export function Page() {
    const location = useLocation();
    const navigate = useNavigate();
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    useEffect(() => {
        const url = new URL(window.location.href);
        url.hash = "#" + serializeSettings(settings, window.location.pathname);
        if (!Global.isRunningStorybook) {
            // In order for userpilot to work properly we need to use the bare url for navigation.
            const urlStr = url.toString().substring((url).origin.length);
            const currentUrlStr = window.location.href.substring(window.location.origin.length);

            // On tablets, things go awkward when we're navigating too often. So to be sure,
            // let's debounce. Also only do that if the fragment actually changed
            if (Global.isDesktop && currentUrlStr !== urlStr) {
                const handle = setTimeout(() => {
                    navigate(urlStr, { replace: true });
                }, 300);

                return () => {
                    clearTimeout(handle);
                };
            }
        } else {
            // For storybook/chromatic we need to do it based on the history to properly work.
            history.replaceState(null, "", url.toString());
            navigate(url, { replace: true });
        }
    }, [
        window.location.pathname,
        getHash(settings),
    ]);


    const changePasswordModalRef = useRef<IModal>(null);
    const settingsModalRef = useRef<IModal>(null);

    const auth0 = useAuth0();

    trackMatomoEvents();

    const isProjectDataSelected = location.pathname.startsWith("/projects/edit") || location.pathname.startsWith("/projects/log") || location.pathname.startsWith("/projects/add");
    useEffect(() => {
        const interval = setInterval(() => {
            regularlyFetchApi();
        }, apiFetchInterval);
        return () => clearInterval(interval);
    }, [settings.rcaStates]);


    // Conditionals for generating the nav
    const isOniqUser = isOniqEmployee(session);

    const navigation: (NavItemProps | NavGroupProps)[] = [];

    navigation.push({
        title: "common.dashboard",
        idSlug: "dashboard",
        targetUrl: () => { return `/projects/${session.projectId}/dashboard`; },
        isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/dashboard`); },
    });

    // Store last known dimension in local storage as a fallback
    const currentDimension = getDimensionFromPathname(location.pathname) ?? localStorage.getItem("dimension") ?? "timings";
    localStorage.setItem("dimension", currentDimension);

    navigation.push({
        title: "common.products",
        idSlug: "kpis",
        targetUrl: () => { return `/projects/${session.projectId}/${currentDimension}/kpis/process`; },
        isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/${currentDimension}/kpis/`); },
    });

    navigation.push({
        title: "supplyChain.menuTitle",
        isVisible: !isEmpty(session.project?.uploads?.billOfMaterials),
        idSlug: "bom",
        targetUrl: () => { return `/projects/${session.projectId}/supply-chain/bom`; },
        isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/supply-chain/`); },
    });

    // Process Graph is also available for all views
    navigation.push({
        title: "workflows.valueStream.title",
        idSlug: "processGraph",
        targetUrl: () => { return `/projects/${session.projectId}/${currentDimension}/process/dfg`; },
        isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/${currentDimension}/process/`); },
    });

    if (session.project?.uploadIdOrderTracking)
        navigation.push({
            title: "common.orderTracking",
            className: "navItemBold",
            idSlug: "order-tracking",
            targetUrl: () => { return `/projects/${session.projectId}/order-tracking/dfg/`; },
            isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/order-tracking/`); },
        });

    navigation.push({
        title: "common.analyses",
        idSlug: "analyses",
        targetUrl: () => { return `/projects/${session.projectId}/analyses`; },
        isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/analyses`); },
    });

    const { trackEvent } = useMatomo();

    // track filter changes
    const isFilterInitialized = useRef<boolean>(false);
    useEffect(() => {
        if (!isFilterInitialized.current) {
            isFilterInitialized.current = true;
            return;
        }

        trackEvent({
            category: "Filters",
            action: settings.filters.length === 0 ? "resetted" : "applied",
            name: (settings.filters ?? []).map(f => getFilterFriendlyName(f)).filter(n => n !== undefined).join(","),
        });
    }, [
        settings.filters
    ]);

    return <>
        <div className="layout">
            <div className="logo">
                <img src="/assets/IQA_Logo_White.svg" height="18" alt="IQ/A logo" />
            </div>

            <div className="navigation">
                <NavGroup
                    isCollapsible={false}
                    className="mbs"
                    childElements={[
                        {
                            title: "common.projects",
                            className: "navItemBold",
                            targetUrl: "/projects",
                            isRouteActive: (url: string) => { return url === "/projects"; },
                            idSlug: "projects",
                        }, {
                            idSlug: "favorites",
                            title: "favorites.menuName",
                            className: "navItemBold",
                            targetUrl: "/favorites",
                            isRouteActive: isFavoritesRoute,
                        }
                    ]} />

                {session?.projectId !== undefined && <div>
                    <div className="separator"></div>

                    <div className="projectName">
                        <div className="inner">
                            {!session.project && <div className="titleSpinner"><ValueSpinner isLoading={true} /></div>}
                            {!!session.project && session?.project?.name}
                        </div>
                    </div>

                    {session.project !== undefined &&
                        <NavGroup childElements={navigation} />
                    }
                </div>}

                <div style={{ flexGrow: 1 }} />
                <div className="menuBottom">
                    {session.project !== undefined && <NavGroup className="bmt" childElements={[{
                        title: "common.settings",
                        isVisible: session.project !== undefined,
                        idSlug: "settings",
                        targetUrl: () => { return `/projects/${session.projectId}/settings/project`; },
                        isRouteActive: (url: string) => { return url === `/projects/${session.projectId}/settings/project`; },
                    }, {
                        title: "common.projectData",
                        isVisible: isProjectDataSelected,
                        childElements: [{
                            isVisible: isOniqUser,
                            title: "common.dataLabels",
                            idSlug: "data-labels",
                            targetUrl: () => { return `/projects/${session.projectId}/edit`; },
                            isRouteActive: (url: string) => { return url === `/projects/${session.projectId}/edit`; },
                        }, {
                            isVisible: !session.project?.uploadIdPlan && isOniqUser,
                            idSlug: "planning",
                            title: "common.planData",
                            targetUrl: () => { return `/projects/${session.projectId}/add-plan`; },
                            isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/add-plan`); },
                        }, {
                            isVisible: !!session.project?.uploadIdPlan && isOniqUser,
                            idSlug: "planning",
                            title: "common.planData",
                            targetUrl: () => { return `/projects/${session.projectId}/edit-plan`; },
                            isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/edit-plan`); },
                        }, {
                            isVisible: !session.project?.uploadIdOrderTracking && isOniqUser,
                            idSlug: "order-tracking",
                            title: "common.orderTrackingData",
                            targetUrl: () => { return `/projects/${session.projectId}/add-order-tracking`; },
                            isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/add-order-tracking`); },
                        }, {
                            isVisible: !!session.project?.uploadIdOrderTracking && isOniqUser,
                            idSlug: "order-tracking",
                            title: "common.orderTrackingData",
                            targetUrl: () => { return `/projects/${session.projectId}/edit-order-tracking`; },
                            isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/edit-order-tracking`); },
                        }, {
                            isVisible: isOniqUser,
                            idSlug: "raw-data",
                            title: "common.rawData",
                            targetUrl: () => { return `/projects/${session.projectId}/log`; },
                            isRouteActive: (url: string) => { return url.startsWith(`/projects/${session.projectId}/log`); },
                        }]
                    }]} />}

                    <a className="navItem noselect" id="userpilot-assistant-anchor">
                        <div className="navItemLevel0">
                            <span>{i18n.t("common.assistant")}</span>
                        </div>
                    </a>

                    <NavGroup className="bmt" childElements={[{
                        markup: <div id="profile-menu" className="navItemLevel0 navProfile">
                            <img
                                src={"https://www.gravatar.com/avatar/" + md5(session!.user!.email) + "?s=96&d=retro"}
                                alt="profile" />
                            <span>
                                {session.user?.name}
                            </span>
                        </div>,
                        childElements: [{
                            title: "profile.changePassword",
                            onClick: () => {
                                changePasswordModalRef.current?.show();
                            }
                        }, {
                            title: "common.profile",
                            onClick: () => {
                                settingsModalRef.current?.show();
                            }
                        }, {
                            title: "profile.logout",
                            onClick: () => auth0.logout({ returnTo: window.location.origin }),
                        }]
                    }]} />

                </div>
                <div style={{ height: 24 }} />
            </div>

            <div className="content">
                {/* Content goes here... */}
                <Routes>
                    {routes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element} />
                    ))}
                </Routes>

                <Spinner isLoading={!!session.projectId && session.project === undefined} text="common.projectInitializing" />
            </div>
            <Drift apiKey={"rafte2uybi2w"} />
        </div>

        <ChangePasswordModal ref={changePasswordModalRef} />

        <SettingsModal ref={settingsModalRef} />

        <NotificationHost />
    </>;

    async function regularlyFetchApi() {
        for (const key of Object.keys(settings.rcaStates)) {
            // Does this line what it is supposed to do?
            const value = getRecentRcaByType(key as unknown as RcaType, settings);

            if (value!.alertUser && value!.id !== undefined && value!.status !== "finished" && value!.status !== "failed") {
                NotificationService.hide("rca-started");

                const result = await Api.getRootCauseAnalysisResults(value!.id);
                if (value!.status !== result.status) {
                    if (result.status === "failed")
                        NotificationService.add(getRcaFailedNotification(result));

                    if (result.status === "finished" &&
                        (value.resultsUrl === undefined || !window.location.pathname.endsWith(value.resultsUrl)))
                        NotificationService.add(getRcaSuccessNotification(() => {
                            updateRecentRcaByType(value.rcaType, settings, {
                                alertUser: false
                            }, true);

                            if (value.resultsUrl)
                                navigate(value.resultsUrl, { replace: false });
                        }));

                    const newState = {
                        ...value,
                        status: result.status,
                        result
                    };

                    updateRecentRcaByType(value!.rcaType, settings, newState, true);
                }
            }
        }
    }
}

export default withTranslation()(withAuth0(App));

type User = {
    email: string,
    name: string,
    picture: string
}

/**
 * Returns the first locale that the application supports
 * @param locales list of locales (may be upper case, more than 2 chars or any kind of falsy)
 * @param allLocales all locales the application supports
 * @returns preferred locale
 */
export function getLocale(locales: (string | undefined | null)[], allLocales: string[]) {
    for (const locale of locales) {
        const sanitized = sanitizeLocale(locale, allLocales);
        if (sanitized)
            // Valid locale found
            return sanitized;
    }

    // No valid locale found, revert to default locale
    return allLocales[0];
}

export function sanitizeLocale(locale: string | undefined | null, locales: string[]) {
    if (!locale || locale === "")
        // default locale
        return undefined;

    locale = locale.toLowerCase();
    if (locale.length > 2)
        locale = locale.substring(0, 2);

    if (locales.indexOf(locale) >= 0)
        return locale;
}

export function getApiErrorNotification() {
    return <Notification
        className="light warning-accent"
        icon="radix-bell"
        summary="errorModal.networkError.title"
        message="errorModal.networkError.msg"
    />;
}

export function getRcaSuccessNotification(onClick: () => void): NotificationProps {
    return {
        className: "light success-accent",
        icon: "radix-check-circled",
        summary: "rca.analysisReadyTitle",
        message: "rca.analysisReadyContent",
        autoCloseDelay: 3000,
        actions: [{
            onClick,
            label: "rca.rcaFinishedButton",
            isPrimary: true,
        }]
    };
}

export function getRcaFailedNotification(result: Partial<GetRootCauseAnalysisResponse>): NotificationProps {
    return {
        summary: "rca.analysisFailedTitle",
        message: <p>{i18n.t(`errorModal.rcaErrors.${result?.error?.type}`)}</p>,
        className: "light warning-accent",
        icon: "radix-bell",
        autoCloseDelay: 15000,
    };
}

export function getPermanentApiErrorNotification(apiError: ApiErrorContextType): NotificationProps {
    const details = apiError?.error?.detail;
    return {
        id: "permanent-api-error",
        summary: "errorModal.generic.title",
        className: "light warning-accent",
        icon: "radix-exclamation-triangle",
        message: <>
            {i18n.t("errorModal.generic.msg")}
            <ul>
                {isArray(details ?? []) ? (details ?? []).map(e => <li key={e.msg}>{e.msg}</li>) : <li>{details?.toString()}</li>}
            </ul>
        </>,
        autoCloseDelay: 15000,
    };
}

function isFavoritesRoute(url: string) {
    return url.startsWith("/favorites");
}