import Global from "../Global";
import { CaseDeviationStatisticsParams, CaseGanttChartRequestType, CaseGanttData, CaseStatisticsTraceOptions, DfgRequest, DistinctAttributeValueResponse, GanttChartRequestType, GanttEntry, GetCaseDeviationResult, GetCaseStatisticsResponse, GetDeviationRequest, GetDistinctAttributeValuesRequest, GetDistinctUploadAttributeValuesRequest, GetDistinctUploadAttributeValuesResponse, GetNodesByCasesResponse, GetProjectVariantsRequest, GetUploadRawEventsRequest, GetUploadsRequest, Histogram, HistogramNumericParams, HistogramTraceOptions, PerProductCaseStatisticsClusteringParams, PerProductCaseStatisticsClusteringResponseSchema, PerProductStatisticsParams, PerTimeperiodStatisticsSchema, PerTimeperiodDeviationStatisticsParams, PerTimeperiodDeviationStatisticsSchema, PerTimeperiodStatisticsParams, PreviewStatisticsResponse, SetupEventsParams, SetupEventsSchema, SetupMatrixParams, SetupMatrixSchema, SupplyChainGraphSchema, TimedeltaHistogramSchema, TimeHistogram, TraceOptions, Variant, EquipmentStatisticsSchema, PerTimeperiodEdgeStatisticsParams, PerTimeperiodEdgeStatisticsSchema, PerTimeperiodEquipmentStatisticsSchema, PerTimeperiodEquipmentStatisticsParams, PerTimeperiodNodeStatisticsSchema, PerTimeperiodNodeStatisticsParams } from "../models/ApiTypes";
import { Api } from "../api/Api";
import { ApiDeviationGraphs, ApiGraph } from "../models/Dfg";
import { Upload } from "../models/Upload";
import { ApiCache } from "./ApiCache";
import { getDefaultMatchHandling, getExactMatchHandling, SmartApiCache } from "./SmartApiCache";
import { PerProductCaseStatisticsSchema, PerProductDeviationStatisticsSchema } from "../models/generated";

// When recording API requests, we cannot use the smart cache, as the order in which the requests are made is undefined.
// E.g. if A is a superset of B, and when recording the API snapshots, A is issued first, then B is not executed at all.
// When in later tests B is executed first, the request then 404s, as only A is cached.
const matchHandling = (Global.isRunningJestTest || Global.isRunningStorybook)? getExactMatchHandling() : getDefaultMatchHandling();

export class Datastores {
    public static dfg: SmartApiCache<ApiGraph, DfgRequest> = new SmartApiCache(Api.getDfg, matchHandling);

    public static statistics: ApiCache<PreviewStatisticsResponse, TraceOptions> = new ApiCache(Api.previewStatistics);

    public static ganttChart: ApiCache<GanttEntry[], GanttChartRequestType> = new ApiCache(Api.getGanttChart);

    public static caseGantt: ApiCache<CaseGanttData, CaseGanttChartRequestType> = new ApiCache(Api.getCaseGanttChart);

    public static distinctAttributeValues: ApiCache<DistinctAttributeValueResponse, GetDistinctAttributeValuesRequest> = new ApiCache(Api.getDistinctAttributeValues);

    public static getTimeHistograms: ApiCache<TimeHistogram, HistogramTraceOptions> = new ApiCache(Api.timeHistogram);

    public static durationHistograms: ApiCache<Histogram, HistogramTraceOptions> = new ApiCache(Api.getDurationHistogram);

    public static getDistinctUploadAttributeValues: ApiCache<GetDistinctUploadAttributeValuesResponse, GetDistinctUploadAttributeValuesRequest> = new ApiCache(Api.getDistinctUploadAttributeValues);

    public static getVariants: ApiCache<Variant[], GetProjectVariantsRequest> = new ApiCache(Api.getVariants);

    public static getCaseStatistics: SmartApiCache<GetCaseStatisticsResponse, CaseStatisticsTraceOptions> = new SmartApiCache(Api.getCaseStatistics, matchHandling);

    public static getUploads: ApiCache<Upload[], GetUploadsRequest> = new ApiCache(Api.getUploads);

    public static getUpload: ApiCache<Upload, string> = new ApiCache(Api.getUpload);

    public static getUploadRawEvents: ApiCache<unknown[], GetUploadRawEventsRequest> = new ApiCache(Api.getUploadRawEvents);

    public static getPerProductCaseDeviationStatistics: SmartApiCache<PerProductDeviationStatisticsSchema, CaseDeviationStatisticsParams> = new SmartApiCache(Api.getPerProductCaseDeviationStatistics, matchHandling);

    public static getPerCaseDeviationStatistics: SmartApiCache<GetCaseDeviationResult, GetDeviationRequest> = new SmartApiCache(Api.getPerCaseDeviationStatistics, matchHandling);

    public static getDeviationGraphs: ApiCache<ApiDeviationGraphs, GetDeviationRequest> = new ApiCache(Api.getDeviationGraphs);

    public static getProductCaseAggregationStatistics: SmartApiCache<PerProductCaseStatisticsSchema, PerProductStatisticsParams> = new SmartApiCache(Api.getProductCaseAggregationStatistics, matchHandling);

    public static getTimeAggregatedCaseStatistics: SmartApiCache<PerTimeperiodStatisticsSchema, PerTimeperiodStatisticsParams> = new SmartApiCache(Api.getTimeAggregatedCaseStatistics, matchHandling);

    /**
     * Caches /api/processmining/deviations/statistics/cases/aggregations/timeperiods
     */
    public static getDeviationTimeperiodStatistics: SmartApiCache<PerTimeperiodDeviationStatisticsSchema, PerTimeperiodDeviationStatisticsParams> = new SmartApiCache(Api.getDeviationTimeperiodStatistics, matchHandling);

    /**
     * Caches requests to /api/processmining/statistics/histograms/cases/numeric
     */
    public static getNumericCaseHistograms: ApiCache<TimedeltaHistogramSchema, HistogramNumericParams> = new ApiCache(Api.getNumericCaseHistograms);
    
    public static getNodesByCases: SmartApiCache<GetNodesByCasesResponse, DfgRequest> = new SmartApiCache(Api.getNodesByCases, matchHandling);

    public static paretoClustering: ApiCache<PerProductCaseStatisticsClusteringResponseSchema, PerProductCaseStatisticsClusteringParams> = new ApiCache(Api.paretoClustering);

    public static getSetupMatrix: ApiCache<SetupMatrixSchema, SetupMatrixParams> = new ApiCache(Api.getSetupMatrix);

    public static getSetupEvents: ApiCache<SetupEventsSchema, SetupEventsParams> = new ApiCache(Api.getSetupEvents);

    public static getEquipmentNodeStatistics: ApiCache<EquipmentStatisticsSchema, DfgRequest> = new ApiCache(Api.getEquipmentNodeStatistics);
    
    public static getSupplyChainGraphs: SmartApiCache<SupplyChainGraphSchema, PerProductStatisticsParams> = new SmartApiCache(Api.getSupplyChainGraphs, matchHandling);

    public static getTimeAggregatedEventStatistics: SmartApiCache<PerTimeperiodStatisticsSchema, PerTimeperiodStatisticsParams> = new SmartApiCache(Api.getTimeAggregatedEventStatistics, matchHandling);

    public static getEdgeAggregationTimeperiods: SmartApiCache<PerTimeperiodEdgeStatisticsSchema, PerTimeperiodEdgeStatisticsParams> = new SmartApiCache(Api.getEdgeAggregationTimeperiods, matchHandling);

    public static getEquipmentAggregationTimeperiods: SmartApiCache<PerTimeperiodEquipmentStatisticsSchema, PerTimeperiodEquipmentStatisticsParams> = new SmartApiCache(Api.getEquipmentAggregationTimeperiods, getDefaultMatchHandling());
    public static getNodeAggregationTimeperiods: SmartApiCache<PerTimeperiodNodeStatisticsSchema, PerTimeperiodNodeStatisticsParams> = new SmartApiCache(Api.getNodeAggregationTimeperiods, getDefaultMatchHandling());

    public static flushAllCaches() {
        Datastores.dfg.flush();
        Datastores.statistics.flush();
        Datastores.ganttChart.flush();
        Datastores.caseGantt.flush();
        Datastores.distinctAttributeValues.flush();
        Datastores.getTimeHistograms.flush();
        Datastores.durationHistograms.flush();
        Datastores.getDistinctUploadAttributeValues.flush();
        Datastores.getVariants.flush();
        Datastores.getCaseStatistics.flush();
        Datastores.getUploads.flush();
        Datastores.getUpload.flush();
        Datastores.getUploadRawEvents.flush();
        Datastores.getDeviationGraphs.flush();
        Datastores.getPerCaseDeviationStatistics.flush();
        Datastores.getProductCaseAggregationStatistics.flush();
        Datastores.getPerProductCaseDeviationStatistics.flush();
        Datastores.getTimeAggregatedCaseStatistics.flush();
        Datastores.getDeviationTimeperiodStatistics.flush();
        Datastores.getNodesByCases.flush();
        Datastores.paretoClustering.flush();
        Datastores.getNumericCaseHistograms.flush();
        Datastores.getSetupMatrix.flush();
        Datastores.getSetupEvents.flush();
        Datastores.getEquipmentNodeStatistics.flush();
        Datastores.getSupplyChainGraphs.flush();
        Datastores.getTimeAggregatedEventStatistics.flush();
        Datastores.getEdgeAggregationTimeperiods.flush();
        Datastores.getEquipmentAggregationTimeperiods.flush();
        Datastores.getNodeAggregationTimeperiods.flush();
        Global.projectLoadingSpinnerHasBeenShown = false;
    }
}
