import * as Sentry from "@sentry/browser";
import axios, { AxiosInstance, AxiosError, CancelToken, AxiosResponse } from "axios";
import { isArray, get, isString } from "lodash";
import Global from "../Global";
import { SessionInstance } from "../contexts/SessionContext";
import { ApiGraph, ApiDeviationGraphs } from "../models/Dfg";
import { Project } from "../models/Project";
import { Upload } from "../models/Upload";
import { groupSupportsConsolidatePasses } from "../utils/GroupingUtils";
import { replaceIdsInObject } from "../utils/IdReplacement";
import { ApiError, ApiErrorContextType, PerProductCaseStatisticsClusteringParams, PerProductCaseStatisticsClusteringResponseSchema, GetDistinctUploadAttributeValuesRequest, GetDistinctUploadAttributeValuesResponse, ColumnInfoUpload, GetDistinctAttributeValuesRequest, DistinctAttributeValueResponse, TraceOptions, GetUploadRawEventsRequest, GetUploadsRequest, GanttChartRequestType, CaseGanttChartRequestType, Eventlog, GetProjectVariantsRequest, Variant, DfgRequest, PreviewStatisticsResponse, PreviewStatistics, HistogramTraceOptions, Histogram, TimeHistogram, PerTimeperiodDeviationStatisticsParams, PerTimeperiodDeviationStatisticsSchema, CaseDeviationStatisticsParams, GetDeviationRequest, GetCaseDeviationResult, PerTimeperiodStatisticsParams, PerTimeperiodStatisticsSchema as PerTimeperiodStatisticsSchema, PerProductStatisticsParams, HistogramNumericParams, TimedeltaHistogramSchema, CaseStatisticsTraceOptions, GetCaseStatisticsResponse, GetNodesByCasesResponse, SetupMatrixParams, SetupMatrixSchema, PostRootCauseAnalysisRequest, PostRootCauseAnalysisResponse, GetRootCauseAnalysisResponse, ActivitySortMode, CaseGanttData, GanttEntry, ViewConfigurations, SetupEventsParams, SetupEventsSchema, SupplyChainGraphSchema, EquipmentStatisticsSchema, PerTimeperiodEdgeStatisticsParams, PerTimeperiodEdgeStatisticsSchema, PerTimeperiodEquipmentStatisticsParams, PerTimeperiodEquipmentStatisticsSchema, PerTimeperiodNodeStatisticsParams, PerTimeperiodNodeStatisticsSchema } from "../models/ApiTypes";
import { backOff } from "exponential-backoff";
import { lowerCardinality, lowerCardinalityPath } from "../utils/Sanitizers";
import { SENTRY_DEFAULT_GROUP } from "../utils/Sentry";
import { getHash } from "../utils/Utils";
import { PerProductCaseStatisticsSchema, PerProductDeviationStatisticsSchema } from "../models/generated";

export class Api {

    /**
     * Private singleton to use for any API call that should be monitored
     * for changed upload IDs
     */
    private static _axios: AxiosInstance;

    private static idReplacementMap: { [id: string]: string } = {};

    private static get axios() {
        if (Api._axios === undefined) {
            Api._axios = axios.create();

            // Add the interceptor that deals with changed upload ids!
            Api._axios.interceptors.response.use((value) => {
                return value;
            }, async (error: AxiosError<ApiError>) => {
                // Check if this error is about a changed upload ID
                // If this is something else, pass it on!
                const uploadChanged = (isArray(error?.response?.data?.detail) ? (error.response?.data.detail ?? []) : []).some(e => e !== undefined && e.type === "UploadNotFoundError");
                if (!uploadChanged)
                    return Promise.reject(error);

                // Add old IDs to the translation map
                const old = { ...SessionInstance.session.project };

                if (old?.uploadId)
                    Api.idReplacementMap[old.uploadId] = "uploadId";

                if (old?.uploadIdPlan)
                    Api.idReplacementMap[old.uploadIdPlan] = "uploadIdPlan";

                if (old?.uploadIdOrderTracking)
                    Api.idReplacementMap[old.uploadIdOrderTracking] = "uploadIdOrderTracking";

                // Reload the project
                let project: Project | undefined;
                let eventUpload: Upload | undefined;
                try {
                    // Important: We must hit the API directly, without any caching
                    project = await Api.getProjectInternal(axios, SessionInstance.session.projectId!);
                    eventUpload = await Api.getUploadInternal(axios, project.uploadId!);
                } catch {
                    // We have really bad luck today...
                    // Desparate situations, desparate measures! 🙈
                    location.reload();
                    return;
                }

                // Build UUID translation map
                const translation: { [id: string]: string } = {};
                for (const [key, value] of Object.entries(Api.idReplacementMap)) {
                    const newValue: string | undefined = get(project, value);
                    if (!newValue)
                        continue;

                    translation[key] = newValue;
                }

                // Modify original request
                const config = error.config!;
                const requestBody = error.config?.data ? JSON.parse(error.config.data) : undefined;
                if (requestBody)
                    config.data = replaceIdsInObject(requestBody, translation);

                config.url = replaceIdsInObject(config.url, translation);

                if (JSON.stringify([SessionInstance.session.project, SessionInstance.session.eventUpload]) !==
                    JSON.stringify([project, eventUpload]))
                    SessionInstance.session.set({
                        project,
                        eventUpload,
                    });

                // Re-issue request
                try {
                    const instance = axios.create();
                    return await instance.request(config);
                } catch (error) {
                    // Bad luck again, but this time, it's not this
                    // interceptor's problem anymore!
                    return Promise.reject(error);
                }
            });
        }

        return Api._axios;
    }

    public static onError: (error: ApiErrorContextType) => void;

    public static onRecovery: () => void;

    /**
     * Get clusters of products.
     * The values of the passed product statistic are sorted in descending order and
     * then divided into clusters based on the percentile cuts.
     */
    static async paretoClustering(options: PerProductCaseStatisticsClusteringParams, cancelToken?: CancelToken) {
        return Api.post<PerProductCaseStatisticsClusteringParams, PerProductCaseStatisticsClusteringResponseSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/cases/aggregations/products/paretoclustering`, options, cancelToken);
    }

    /**
     * Returns distinct values for a given column of an uploaded file
     * @param uploadId ID of the upload to scan
     * @param attributes Attribute columns to check for distinct values
     * @param maxValues Maximum number of results
     * @returns Distinct values of the given columns
     */
    static async getDistinctUploadAttributeValues(options: GetDistinctUploadAttributeValuesRequest, cancelToken?: CancelToken): Promise<GetDistinctUploadAttributeValuesResponse> {
        const response = await Api.axios.get<{
            name: string;
            values: ColumnInfoUpload[]
        }[]>(`${Global.apiEndpoint}/api/processmining/uploads/${options.uploadId}/statistics/attributes/values`, {
            ...Global.defaultRequestOptions,
            cancelToken,
            params: {
                maxValues: options.maxValues,
                attribute: options.attributes
            }
        });

        return response.data;
    }

    /**
     * Returns distinct values for given attributes of an uploaded file
     * @param options Options
     * @param attributes Attribute columns to check for distinct values
     * @param maxValues Maximum number of results
     * @param combinations Flag whether combinations should be returned
     * @returns Distinct values of the given attributes
     */
    static async getDistinctAttributeValues(options: GetDistinctAttributeValuesRequest, cancelToken?: CancelToken) {
        return Api.post<GetDistinctAttributeValuesRequest, DistinctAttributeValueResponse>(
            `${Global.apiEndpoint}/api/processmining/statistics/attributes/values`, options, cancelToken);
    }

    /**
     * Creates a new project
     * @param options Options
     * @returns Entity of the created project
     */
    static async createProject(options: TraceOptions & { name: string }, cancelToken?: CancelToken) {
        return Api.post<TraceOptions & { name: string }, Project>(
            `${Global.apiEndpoint}/api/processmining/projects`, options, cancelToken);
    }

    static async updateProject(project: Project, cancelToken?: CancelToken) {
        return Api.post<Project, Project>(
            `${Global.apiEndpoint}/api/processmining/projects/${project.id}`, project, cancelToken);
    }

    /**
     * Fetches a collection of projects based on the filter options provided
     * @param options Options
     * @returns Collection of projects that pass the filter settings provided
     */
    static async getProjects(options: {
        offset?: number | undefined,
        limit?: number | undefined,
        sort?: "id" | "created" | "updated" | "userId" | "name" | "uploadId" | "eventKeys" | "-id" | "-created" | "-updated" | "-userId" | "-name" | "-uploadId" | "-eventKeys" | undefined,
        fields?: ("id" | "created" | "updated" | "userId" | "name" | "description" | "uploadId" | "eventKeys" | "eventFilters")[] | undefined,
        idEq?: string[] | undefined,
        createdLt?: string | Date | undefined,
        createdLe?: string | Date | undefined,
        createdGt?: string | Date | undefined,
        createdGe?: string | Date | undefined,
        updatedLt?: string | Date | undefined,
        updatedLe?: string | Date | undefined,
        updatedGt?: string | Date | undefined,
        updatedGe?: string | Date | undefined,
        nameEq?: string | undefined
    }): Promise<Project[]> {
        const response = await retryPromise<AxiosResponse<Project[]>>(() => {
            return Api.axios.get(
                `${Global.apiEndpoint}/api/processmining/projects`,
                {
                    ...Global.defaultRequestOptions,
                    params: {
                        offset: options.offset ?? 0,
                        limit: options.limit,
                        sort: options.sort ?? "-updated",
                        fields: options.fields ?? ["id", "created", "updated", "userId", "name", "description", "uploadId", "eventKeys", "eventFilters"],
                        "id[eq]": options.idEq,
                        "created[lt]": options.createdLt,
                        "created[le]": options.createdLe,
                        "created[gt]": options.createdGt,
                        "created[ge]": options.createdGe,
                        "updated[lt]": options.updatedLt,
                        "updated[le]": options.updatedLe,
                        "updated[gt]": options.updatedGt,
                        "updated[ge]": options.updatedGe,
                        "name[eq]": options.nameEq,
                    }
                }
            );
        });

        return response.data.map((r: Project) => {
            return {
                ...r,
                created: Api.date(r.created),
                updated: Api.date(r.updated),
            } as Project;
        });
    }


    /**
     * Returns a specific project, identified by it's id
     * @param id Project ID
     * @returns Project entity
     */
    static async getProject(id: string, cancelToken?: CancelToken): Promise<Project> {
        return await retryPromise<Project>(() => {
            return Api.getProjectInternal(Api.axios, id, cancelToken);
        });
    }

    /**
     * Hits the API without without any extras such as retrying or upload change detection.
     */
    static async getProjectRaw(id: string, cancelToken?: CancelToken) {
        return this.getProjectInternal(axios, id, cancelToken);
    }

    private static async getProjectInternal(instance: AxiosInstance, id: string, cancelToken?: CancelToken): Promise<Project> {
        const response = await instance.get<Project>(
            `${Global.apiEndpoint}/api/processmining/projects/${id}`,
            {
                ...Global.defaultRequestOptions,
                cancelToken
            }
        );

        return Api.jsonToProject(response.data);
    }

    public static jsonToProject(json: Project): Project {
        return {
            ...json,
            created: Api.date(json.created),
            updated: Api.date(json.updated),
        };
    }

    /**
     * Fetches a collection of View Configurations based on the filter options provided
     * @param options Options
     * @returns Collection of View Configurations that pass the filter settings provided
     */
    static async getViewConfigurations(options: {
        offset?: number | undefined,
        limit?: number | undefined,
        sort?: "id" | "created" | "updated" | "userId" | "name" | "projectId" | "viewId"  | "-viewId"  | "-id" | "-created" | "-updated" | "-userId" | "-name" | "-projectId" | undefined,
        fields?: ("id" | "created" | "updated" | "userId" | "name" | "description" | "viewId" |"projectId" | "organizationId" | "isSharedWithOrganization")[] | undefined,
        idEq?: string[] | undefined,
        createdLt?: string | Date | undefined,
        createdLe?: string | Date | undefined,
        createdGt?: string | Date | undefined,
        createdGe?: string | Date | undefined,
        updatedLt?: string | Date | undefined,
        updatedLe?: string | Date | undefined,
        updatedGt?: string | Date | undefined,
        updatedGe?: string | Date | undefined,
        nameEq?: string | undefined,
        projectIdEq?: string | undefined,
        viewTypeEq?: string | undefined
    }): Promise<ViewConfigurations[]> {
        const response = await retryPromise<AxiosResponse<ViewConfigurations[]>>(() => {
            return Api.axios.get(
                `${Global.apiEndpoint}/api/processmining/viewconfigurations`,
                {
                    ...Global.defaultRequestOptions,
                    params: {
                        offset: options.offset ?? 0,
                        limit: options.limit,
                        sort: options.sort ?? "-updated",
                        fields: options.fields ?? ["id", "created", "updated", "userId", "viewId", "settings", "name", "description", "projectId", "organizationId", "isSharedWithOrganization"],
                        "id[eq]": options.idEq,
                        "created[lt]": options.createdLt,
                        "created[le]": options.createdLe,
                        "created[gt]": options.createdGt,
                        "created[ge]": options.createdGe,
                        "updated[lt]": options.updatedLt,
                        "updated[le]": options.updatedLe,
                        "updated[gt]": options.updatedGt,
                        "updated[ge]": options.updatedGe,
                        "name[eq]": options.nameEq,
                        "projectId[eq]": options.projectIdEq,
                        "viewType[eq]": options.viewTypeEq,
                    }
                }
            );
        });

        return response.data.map((r: ViewConfigurations) => {
            return {
                ...r,
                created: Api.date(r.created),
                updated: Api.date(r.updated),
            } as ViewConfigurations;
        });
    }

    static async getViewConfigurationsById(id: string, cancelToken?: CancelToken) {
        const response = await retryPromise<AxiosResponse<ViewConfigurations>>(() => {
            return Api.axios.get<ViewConfigurations>(
                `${Global.apiEndpointThinker}/api/processmining/viewconfigurations/${id}`,
                {
                    ...Global.defaultRequestOptions,
                    cancelToken
                }
            );
        });

        return response.data;
    }

    /**
     * Creates a new View Configurations
     * @param options Options
     * @returns Entity of the created View Configurations
     */
    static async createViewConfigurations(viewConfigurations: ViewConfigurations, cancelToken?: CancelToken) {
        return await Api.post<ViewConfigurations, ViewConfigurations>(
            `${Global.apiEndpoint}/api/processmining/viewconfigurations`, viewConfigurations, cancelToken);
    }

    static async updateViewConfigurations(viewConfigurations: ViewConfigurations, cancelToken?: CancelToken) {
        return await Api.post<ViewConfigurations, ViewConfigurations>(
            `${Global.apiEndpoint}/api/processmining/viewconfigurations/${viewConfigurations.id}`, viewConfigurations, cancelToken);
    }

    static async getSupplyChainGraphs(options: PerProductStatisticsParams, cancelToken?: CancelToken) {
        return await Api.post<PerProductStatisticsParams, SupplyChainGraphSchema>(
            `${Global.apiEndpoint}/api/processmining/supplychaingraphs`, options, cancelToken);
    }

    /**
     * Delete a View Configuration
     * @param id ID of the View Configuration to delete
     */
    static async deleteViewConfigurations(id: string, cancelToken?: CancelToken) {
        await retryPromise<AxiosResponse<string>>(() => {
            return Api.axios.delete(
                `${Global.apiEndpoint}/api/processmining/viewconfigurations/${id}`,
                {
                    ...Global.defaultRequestOptions,
                    cancelToken
                });
        });
    }

    /**
     * Returns an upload entity by ID
     * @param id ID of the upload entity to return
     * @returns Upload entity
     */
    static async getUpload(id: string, cancelToken?: CancelToken) {
        return await retryPromise<Upload>(() => {
            return Api.getUploadInternal(Api.axios, id, cancelToken);
        });
    }

    static async getUploadRaw(id: string, cancelToken?: CancelToken) {
        return this.getUploadInternal(axios, id, cancelToken);
    }

    static async getUploadInternal(instance: AxiosInstance, id: string, cancelToken?: CancelToken) {
        const response = await instance.get<Upload>(
            `${Global.apiEndpoint}/api/processmining/uploads/${id}`,
            {
                ...Global.defaultRequestOptions,
                cancelToken
            });

        return {
            ...response.data,
            created: new Date(response.data.created),
            uploaded: new Date(response.data.uploaded),
        };
    }

    /**
     * Deletes a project entity by ID.
     * Does NOT delete the uploads, as these might be used in other projects.
     * @param id ID of the project entity to delete
     */
    static async deleteProject(id: string, cancelToken?: CancelToken) {
        await retryPromise<AxiosResponse<unknown>>(() => {
            return Api.axios.delete(
                `${Global.apiEndpoint}/api/processmining/projects/${id}`,
                {
                    ...Global.defaultRequestOptions,
                    cancelToken
                });
        });
    }

    /**
     * Deletes an upload entity by ID
     * @param id ID of the upload entity to delete
     */
    static async deleteUpload(id: string, cancelToken?: CancelToken) {
        await retryPromise<AxiosResponse<Upload>>(() => {
            return Api.axios.delete<Upload>(
                `${Global.apiEndpoint}/api/processmining/uploads/${id}`,
                {
                    ...Global.defaultRequestOptions,
                    cancelToken
                });
        });
    }

    /**
     * Returns raw event data of the upload specified. This can be useful for identifying
     * columns
     * @param id ID of the upload
     * @param limit Return at most this many rows
     * @param offset Skip this many rows of the result set
     */
    static async getUploadRawEvents(options: GetUploadRawEventsRequest, cancelToken?: CancelToken) {
        const response = await retryPromise<AxiosResponse<unknown[]>>(() => {
            return Api.axios.get(`${Global.apiEndpoint}/api/processmining/uploads/${options.id}/events`,
                {
                    ...Global.defaultRequestOptions,
                    cancelToken,
                    params: {
                        limit: options.limit ?? 10,
                        offset: options.offset ?? 0
                    }
                }
            );
        });
        return response.data;
    }

    /**
     * Returns a collection of Upload entities that match the filter settings
     * @param options Filter options
     * @returns Collection of Upload entities
     */
    static async getUploads(options: GetUploadsRequest, cancelToken?: CancelToken) {
        const response = await retryPromise<AxiosResponse<Upload[]>>(() => {
            return Api.axios.get(
                `${Global.apiEndpoint}/api/processmining/uploads`,
                {
                    ...Global.defaultRequestOptions,
                    cancelToken,
                    params: {
                        offset: options.offset || 0,
                        limit: options.limit || 100,
                        sort: options.sort || "-created,id",
                        fields: (options.fields || ["id", "userId", "created", "uploaded", "filename", "filenameExtension", "size", "nEvents", "meta"]),
                        "id[eq]": options.idEq,
                        "created[lt]": options.createdLt,
                        "created[le]": options.createdLe,
                        "created[gt]": options.createdGt,
                        "created[ge]": options.createdGe,
                        "updated[lt]": options.updatedLt,
                        "updated[le]": options.updatedLe,
                        "updated[gt]": options.updatedGt,
                        "updated[ge]": options.updatedGe,
                        "size[lt]": options.sizeLt,
                        "size[le]": options.sizeLe,
                        "size[gt]": options.sizeGt,
                        "size[ge]": options.sizeGe,
                        "filenameExtension[eq]": options.filenameExtensionEq,
                    }
                },
            );
        });

        return response.data.map((d: Upload) => {
            return {
                ...d,
                created: Api.date(d.created),
                uploaded: Api.date(d.uploaded),
            };
        }) as Upload[];
    }

    static async getGanttChart(options: GanttChartRequestType, cancelToken?: CancelToken) {
        // This API function does not use the common retry logic, as we need to capture HTTP/422
        // in the process gantt view.
        return (await Api.axios.post<GanttEntry[]>(
            `${Global.apiEndpoint}/api/processmining/ganttcharts`,
            options,
            {
                ...Global.defaultRequestOptions,
                cancelToken
            })).data;
    }

    static async getCaseGanttChart(options: CaseGanttChartRequestType, cancelToken?: CancelToken) {
        const response = await retryPromise<AxiosResponse<CaseGanttData>>(() => {
            return Api.axios.post<CaseGanttData>(
                `${Global.apiEndpoint}/api/processmining/caseganttcharts`,
                {
                    ...options,
                    timeMode: options.timeMode ?? "caseRelative",
                    activitySortMode: options.sortMode ?? ActivitySortMode.Alphabetical
                },
                {
                    ...Global.defaultRequestOptions,
                    cancelToken
                }
            );
        });
        return response.data;
    }

    /**
     * Fetch the eventlog
     * @param options filter settings, column keys and upload id as well as pagination options
     * @returns
     */
    static async getEventlog(options: TraceOptions & { limit?: number, offset?: number }, cancelToken?: CancelToken) {
        return Api.post<TraceOptions & { limit?: number, offset?: number }, Eventlog>(
            `${Global.apiEndpoint}/api/processmining/eventlogs`, options, cancelToken);
    }

    static async getVariants(options: GetProjectVariantsRequest, cancelToken?: CancelToken) {
        return Api.post<GetProjectVariantsRequest, Variant[]>(
            `${Global.apiEndpoint}/api/processmining/variants`, options, cancelToken);
    }

    /**
     * Fetches a DFG based on the options provided
     * @param options filter settings, column keys and upload id
     * @param cancelToken the cancel token to use along with this request
     * @returns The DFG instance
     */
    static async getDfg(options: DfgRequest, cancelToken?: CancelToken) {
        return Api.post<DfgRequest, ApiGraph>(
            `${Global.apiEndpoint}/api/processmining/dfgs`,
            {
                ...options,
                consolidatePasses: groupSupportsConsolidatePasses(options.eventKeys.activityKeysGroup),
            },
            cancelToken);
    }

    /**
     * Returns (very) basic statistics about the cases that pass the filter
     * @param options filter settings, column keys and upload id
     * @returns basic statistics
     */
    static async previewStatistics(options: TraceOptions, cancelToken?: CancelToken): Promise<PreviewStatisticsResponse> {
        const data = await Api.post<TraceOptions, PreviewStatistics>(
            `${Global.apiEndpoint}/api/processmining/statistics`, options, cancelToken);

        return {
            ...data,
            startTime: Api.date(data?.startTime),
            endTime: Api.date(data?.endTime)
        };
    }

    /**
     * Returns a histogram for case durations
     * @param options filter settings, column keys, upload id and number of bins
     * @returns histogram as lists of bins and counts
     */
    static async getDurationHistogram(options: HistogramTraceOptions, cancelToken?: CancelToken) {
        return Api.post<HistogramTraceOptions, Histogram>(
            `${Global.apiEndpoint}/api/processmining/statistics/histograms/cases/durations`, options, cancelToken);
    }

    /**
     * Returns a histogram for case times
     * @param options filter settings, column keys, upload id and number of bins
     * @returns histogram as lists of bins and counts
     */
    static async timeHistogram(options: HistogramTraceOptions, cancelToken?: CancelToken) {
        return Api.post<HistogramTraceOptions, TimeHistogram>(
            `${Global.apiEndpoint}/api/processmining/statistics/histograms/cases/times`, options, cancelToken);
    }

    /**
     * Get per timeperiods deviation statistics
     * Queries /api/processmining/deviations/statistics/cases/aggregations/timeperiods
     * See https://staging.oniq.dev/api/processmining/docs#/Process%20mining/get_per_timeperiods_deviation_statistics_api_processmining_deviations_statistics_cases_aggregations_timeperiods_post
     * @param options
     * @param cancelToken
     * @returns
     */
    static async getDeviationTimeperiodStatistics(options: PerTimeperiodDeviationStatisticsParams, cancelToken?: CancelToken) {
        return Api.post<PerTimeperiodDeviationStatisticsParams, PerTimeperiodDeviationStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/deviations/statistics/cases/aggregations/timeperiods`, options, cancelToken);
    }

    static async getEdgeAggregationTimeperiods(options: PerTimeperiodEdgeStatisticsParams, cancelToken?: CancelToken) {
        return Api.post<PerTimeperiodEdgeStatisticsParams, PerTimeperiodEdgeStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/edges/aggregations/timeperiods`, options, cancelToken);
    }

    static async getEquipmentAggregationTimeperiods(options: PerTimeperiodEquipmentStatisticsParams, cancelToken?: CancelToken) {
        return Api.post<PerTimeperiodEquipmentStatisticsParams, PerTimeperiodEquipmentStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/equipment/aggregations/timeperiods`, options, cancelToken);
    }

    static async getNodeAggregationTimeperiods(options: PerTimeperiodNodeStatisticsParams, cancelToken?: CancelToken) {
        return Api.post<PerTimeperiodNodeStatisticsParams, PerTimeperiodNodeStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/nodes/aggregations/timeperiods`, options, cancelToken);
    }


    /**
     * Returns product case deviation statistics
     * @param options Request options
     * @param cancelToken Cancellation Token
     * @returns Promise that eventually resolves to an `GetDeviationResult` instance
     */
    static async getPerProductCaseDeviationStatistics(options: CaseDeviationStatisticsParams, cancelToken?: CancelToken) {
        return Api.post<CaseDeviationStatisticsParams, PerProductDeviationStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/deviations/statistics/cases/aggregations/products`, options, cancelToken);
    }

    /**
     * Returns case deviation statistics
     * @param options Request options
     * @param cancelToken Cancellation Token
     * @returns Promise that eventually resolves to an `GetDeviationResult` instance
     */
    static async getPerCaseDeviationStatistics(options: GetDeviationRequest, cancelToken?: CancelToken) {
        return Api.post<GetDeviationRequest, GetCaseDeviationResult>(
            `${Global.apiEndpoint}/api/processmining/deviations/statistics/cases`, options, cancelToken);
    }

    /**
     * Returns two graphs (actual and planned), the deviation statistics can be found in the actual graph
     * @param options Request options
     * @param cancelToken Cancellation Token
     * @returns Promise that eventually resolves to an `GraphDeviation` instance
     */
    static async getDeviationGraphs(options: GetDeviationRequest, cancelToken?: CancelToken) {
        const data = await Api.post<GetDeviationRequest, ApiDeviationGraphs>(
            `${Global.apiEndpoint}/api/processmining/deviations/dfgs`, options, cancelToken);

        // Copy deviation object from actual to planned nodes
        // This is necessary to display node deviation stats in the aside when
        // showing the side-by-side deviation DFG.
        for (const node of data.actual?.nodes ?? []) {
            const plannedNode = data.planned?.nodes.find(n => n.id === node.id);
            if (!plannedNode)
                continue;

            plannedNode.deviation = node.deviation;
        }

        return data;
    }

    static async getTimeAggregatedEventStatistics(options: PerTimeperiodStatisticsParams, cancelToken?: CancelToken) {
        return await Api.post<PerTimeperiodStatisticsParams, PerTimeperiodStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/events/aggregations/timeperiods`, options, cancelToken);
    }

    static async getTimeAggregatedCaseStatistics(options: PerTimeperiodStatisticsParams, cancelToken?: CancelToken) {
        return await Api.post<PerTimeperiodStatisticsParams, PerTimeperiodStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/cases/aggregations/timeperiods`, options, cancelToken);
    }

    static async getProductCaseAggregationStatistics(options: PerProductStatisticsParams, cancelToken?: CancelToken) {
        return await Api.post<PerProductStatisticsParams, PerProductCaseStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/cases/aggregations/products`, options, cancelToken);
    }

    static async getNumericCaseHistograms(options: HistogramNumericParams, cancelToken?: CancelToken): Promise<TimedeltaHistogramSchema> {
        return await Api.post<HistogramNumericParams, TimedeltaHistogramSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/histograms/cases/numeric`, options, cancelToken);
    }

    /**
     * Returns case statistics
     * @param options filter settings, column keys and upload id
     * @returns array of case statistics
     */
    static async getCaseStatistics(options: CaseStatisticsTraceOptions, cancelToken?: CancelToken): Promise<GetCaseStatisticsResponse> {
        const data = await Api.post<CaseStatisticsTraceOptions, GetCaseStatisticsResponse>(
            `${Global.apiEndpoint}/api/processmining/statistics/cases`, options, cancelToken);

        // Convert date strings to date objects
        (data.cases ?? []).forEach((stat: any) => {
            stat.id = stat?.id ?? stat?.caseId;
            stat.startTime = new Date(stat.startTime);
            stat.endTime = new Date(stat.endTime);
        });

        return data;
    }

    /**
     * Get node statistics for each node and case
     * @param options Request options
     * @param cancelToken Cancellation Token
     * @returns  nodes by case statistics
     */
    static async getNodesByCases(options: DfgRequest, cancelToken?: CancelToken) {
        const response = await retryPromise<AxiosResponse<GetNodesByCasesResponse>>(() => {
            return Api.axios.post<GetNodesByCasesResponse>(
                `${Global.apiEndpoint}/api/processmining/statistics/dfg/nodesbycases`,
                {
                    ...options
                },
                {
                    ...Global.defaultRequestOptions,
                    cancelToken
                }
            );
        });

        return response.data;
    }

    static async getSetupMatrix(options: SetupMatrixParams, cancelToken?: CancelToken) {
        return Api.post<SetupMatrixParams, SetupMatrixSchema>(
            `${Global.apiEndpoint}/api/processmining/setupmatrices`, options, cancelToken);
    }

    static async getEquipmentNodeStatistics(options: DfgRequest, cancelToken?: CancelToken) {
        return Api.post<DfgRequest, EquipmentStatisticsSchema>(
            `${Global.apiEndpoint}/api/processmining/statistics/equipment`, options, cancelToken);
    }

    /**
     * Start the calculation of a root cause analysis
     * @param options parameters for the datasets (actual, planned) incl. filters, target definition and features
     * @returns an id of the submitted root cause analysis calculation for later retrieval
     */
    static async postRootCauseAnalysis(options: PostRootCauseAnalysisRequest, cancelToken?: CancelToken) {
        return Api.post<PostRootCauseAnalysisRequest, PostRootCauseAnalysisResponse>(
            `${Global.apiEndpointThinker}/api/ai/rca/analyses`, options, cancelToken);
    }

    static async getSetupEvents(options: SetupEventsParams, cancelToken?: CancelToken) {
        return Api.post<SetupEventsParams, SetupEventsSchema>(
            `${Global.apiEndpoint}/api/processmining/setupmatrices/events`, options, cancelToken);
    }

    /**
     * Retrieve the results of a root cause analysis
     * @param id id of the root cause analysis
     * @param invokeDefaultErrorHandler whether to invoke the default error handler (that displays
     * a user notification and logs the error to sentry)
     * @returns root cause analysis results
     */
    static async getRootCauseAnalysisResults(id: string, cancelToken?: CancelToken, invokeDefaultErrorHandler = true) {
        const response = await retryPromise<AxiosResponse<GetRootCauseAnalysisResponse>>(() => {
            return Api.axios.get<GetRootCauseAnalysisResponse>(
                `${Global.apiEndpointThinker}/api/ai/rca/analyses/${id}/results`,
                {
                    ...Global.defaultRequestOptions,
                    cancelToken
                }
            );
        }, invokeDefaultErrorHandler);

        return response.data;
    }

    // #region Helpers

    /**
     * Converts a date to a Date object
     * @param val string, date object or undefined are all welcome here
     */
    private static date(val: string | Date | undefined) {
        if (!val)
            return undefined;

        // I've seen cases where timestamps came without any timezone specified. If that's the case,
        // weird things start to happen, so I'm adding UTC. However, in a perfect world,
        // there was no need for this code.
        if (isString(val))
            if (val.indexOf("+") < 0 && val.indexOf("Z") < 0)
                val += "Z";

        return new Date(val);
    }
    // #endregion

    /**
     * Helper method for issuing post requests that are automatically retried upon error
     * and intercepts HTTP/422. Basically that's what you want for a regular POST request
     * towards the ApiCalls.
     *
     * FIXME: One day moving this into an interceptor would be nice, because
     *  - the entire API interception middleware would be in one place and
     *  - the retryPromise library is poorly maintained and adds extra complexity that
     *    just is justified. It smells left-paddy.
     */
    private static async post<REQUEST, RESPONSE>(url: string, options: REQUEST, cancelToken?: CancelToken) {
        const response = await retryPromise<AxiosResponse<RESPONSE>>(() => {
            return Api.axios.post<RESPONSE>(
                url,
                options,
                {
                    ...Global.defaultRequestOptions,
                    cancelToken
                });
        });

        return response.data;
    }
}

function retryPromise<T>(operation: () => Promise<T>, invokeDefaultErrorHandler = true): Promise<T> {
    const numOfAttempts = 3;
    const initialDelay = Global.isRunningJestTest ? 0 : 2000;
    const retryOnStatus = [501, 502, 503, 504, 408, 418, 425];

    const retrier = (err: any, attempt: number) => {
        // Return false if we give up on retrying
        if (axios.isCancel(err))
            // Cancelling requests is fine
            return false;

        const doRetry = (err?.code === "ERR_NETWORK" || (retryOnStatus.indexOf(+(err?.response?.status ?? -1)) >= 0));

        if (doRetry && attempt === numOfAttempts)
            handleError(err);

        return doRetry;
    };

    return backOff(operation, {
        numOfAttempts: Number.MAX_SAFE_INTEGER,
        startingDelay: initialDelay,
        maxDelay: 5 * 60 * 1000, // 5 minutes
        timeMultiple: 1.5,
        jitter: "full",
        retry: retrier,
    }).then((result) => {
        if (Api.onRecovery !== undefined)
            Api.onRecovery();

        return result;
    }).catch((error) => {
        if (!axios.isCancel(error) && invokeDefaultErrorHandler)
            handleError(error);

        throw error;
    });
}



const sentryFingerprints = new Set<string>();

export function handleError(e: any) {
    if (axios.isCancel(e)) {
        return;  // Cancelling requests is fine
    }

    // Since this error is handled and no longer uncaught, sentry will not log
    // the error automatically.  However, we still want to know about failed
    // requests so we capture explicitly as long as this error is previously unknown.
    const fingerprint = buildSentryFingerprint(e);

    const extra = (+(e.request?.status ?? "0")) >= 400 ? {
        extra: {
            response: e?.request?.responseText,
            request: e?.config?.data,
        },
    } : undefined;

    const fingerprintHash = getHash(fingerprint);
    if (!sentryFingerprints.has(fingerprintHash)) {
        sentryFingerprints.add(fingerprintHash);
        Sentry.captureException(e, {
            fingerprint,
            ...extra,
        });
    }


    if (Api.onError) {
        if (e.response) {
            // We got a response from the server
            if (e.response.data?.detail) {
                Api.onError({
                    isFaulty: true,
                    isRetryable: false,
                    error: e.response.data as ApiError
                });
                return;
            }
        }

        // Show generic "connection broken" modal
        Api.onError({
            isFaulty: true,
            isRetryable: true,
            error: undefined,
        });
    }
}

/**
 * Build a fingerprint to identify a group of sentry error.
 *
 * This is used to split up API errors so that they are not thrown together as
 * the same error (regardless of which endpoint or caller caused the error).
 *
 * For more on fingerprinting see:
 * https://docs.sentry.io/platforms/javascript/usage/sdk-fingerprinting/
 */
function buildSentryFingerprint(e: any) {
    const fingerprint = [
        SENTRY_DEFAULT_GROUP,
        lowerCardinality(location?.pathname || ""),
    ];
    if (axios.isAxiosError(e))
        fingerprint.push(...[
            e.response?.config?.method ?? e.request?.config?.method ?? "",
            (e.response?.status || 0).toString(),
            lowerCardinalityPath(e.response?.config?.url || "")
        ]);

    return fingerprint;
}


export function ignoreCancelledRequest(error: any) {
    if (axios.isCancel(error)) {
        // Cancelling requests is fine
        return;
    }

    throw error;
}