import React, { useContext } from "react";
import { TimePeriodFrequencies } from "../../models/ApiTypes";
import { SessionContext } from "../../contexts/SessionContext";
import i18n from "../../i18n";
import { Point } from "../../models/Dfg";
import { Formatter, UnitMetadata, UnitScale } from "../../utils/Formatter";
import { LineGraph } from "../graph/LineGraph";
import Spinner from "../spinner/Spinner";
import { Spotlight } from "../spotlight/Spotlight";
import { KpiPresets, KpiTypes, StatisticTypes } from "../../models/KpiTypes";
import Menu, { MenuItem } from "../menu/Menu";
import { AggregationTypes, KpiComparisons } from "../../contexts/ContextTypes";
import { useNavigate } from "react-router-dom";
import { SettingsContext, SettingsType, getPushedHistory } from "../../contexts/SettingsContext";
import { DeepPartial, ObjectMerger } from "../../utils/ObjectMerger";
import Global, { getPreferences, setPreferences } from "../../Global";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { DataGapFilling, getKpiDefinition } from "../../models/Kpi";
import { isNiceNumber } from "../../utils/Utils";

export type DashboardTileProps = {
    title: string;

    kpiType: KpiTypes | undefined;

    value: number;
    prevValue: number;
    planValue?: number;

    values: Point[];
    planValues: Point[];

    unit: UnitMetadata;

    isLessBetter?: boolean;

    isLoading?: boolean;

    isSelected?: boolean;

    scales: UnitScale[];

    statistic?: StatisticTypes;

    xTickFrequency?: TimePeriodFrequencies;

    spotlight?: string;

    onSettingsClick?: () => void;

    onClick?: () => void;
};

export function DashboardTile(props: DashboardTileProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const navigate = useNavigate();

    const { trackEvent } = useMatomo();

    const [isHoveredUpon, setIsHoveredUpon] = React.useState(false);

    const kpiDefinition = getKpiDefinition(props.kpiType, { session, settings });

    const maxValue = Math.max(...props.values.concat(props.planValues ?? []).filter(a => isNiceNumber(a.y)).map(a => Math.abs(a.y)));

    // The devision here is how many ticks we're planning to draw.
    // We want reasonable units for that, so this is the range we expect
    // for a tick to cover at least.
    const scale = Formatter.getUnit(props.scales, maxValue / 5)!;

    // Calculate relative delta to plan
    const deltaPlanPercent = getDeltaPercent(props.value, props.planValue);
    const deltaPrevPercent = getDeltaPercent(props.value, props.prevValue);

    // Calculate delta to previous interval
    const deltaPrev: number | undefined = props.prevValue !== undefined ?
        props.value - props.prevValue :
        undefined;

    const isLoading = props.isLoading === true;

    const actions: MenuItem[] = [];
    const kpiDimension = props.kpiType ?
        KpiPresets.productTimeKpis.includes(props.kpiType) ? "timings" :
            KpiPresets.productOutputKpis.includes(props.kpiType) ? "output" :
                KpiPresets.productQualityKpis.includes(props.kpiType) ? "quality" :
                    KpiPresets.productInventoryKpis.includes(props.kpiType) ? "stock" : "carbon" :
        "";

    actions.push({
        title: "dashboard.actions.showProductComparison",
        onClick: () => {
            go("kpi-per-product", `/${kpiDimension}/kpis/process`, { kpi: { statistic: props.statistic, selectedKpi: props.kpiType, aggregation: AggregationTypes.Product, comparisons: props.planValue === undefined ? KpiComparisons.None : KpiComparisons.Planning } });
        },
    });

    actions.push({
        title: "dashboard.actions.showOverTime",
        onClick: () => {
            go("kpi-over-time", `/${kpiDimension}/kpis/process`, { kpi: { statistic: props.statistic, selectedKpi: props.kpiType, timeScale: settings.dashboard?.frequency, aggregation: AggregationTypes.Time } });
        },
    });

    const showMenu = props.isSelected || (!Global.isTouchEnabled && isHoveredUpon);

    return <div className={"dashboardTile" + (props.isSelected ? " selected" : "")}
        onMouseEnter={() => setIsHoveredUpon(true)}
        onMouseLeave={() => {
            setIsHoveredUpon(false);
        }}
        onClick={(e) => {
            if (props.onClick !== undefined)
                props.onClick();
            e.preventDefault();
        }}>
        <Spinner isLoading={isLoading} />

        {!props.isLoading && <>
            <div className="header">
                <div className="title">
                    <span>{i18n.t(props.title)}</span>

                    {props.spotlight && <Spotlight id={props.spotlight} className=" " />}

                    {props.onSettingsClick !== undefined && <svg className="svg-icon xxsmall clickable brandHover edit" data-testid="tile-options" onClick={(e) => {
                        props.onSettingsClick?.();
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <use xlinkHref="#radix-pencil-1" />
                    </svg>}

                    {showMenu && <Menu items={actions} className="menuLight" onBlur={() => {
                        setIsHoveredUpon(false);
                    }
                    }>
                        <button className="shortcutButton">
                            {i18n.t("common.actions")}
                            <svg className="svg-icon tiny rotate180"><use xlinkHref="#collapser" /></svg>
                        </button>
                    </Menu>}
                </div>

                <div className="table">
                    <div className="cell">
                        <div className="headline">
                            {i18n.t("dashboard.actual")}
                        </div>

                        <div className="absoluteTime">
                            <div className="probe actual" />
                            {Formatter.formatSpecificUnit(scale, props.value, 1, " ", session.numberFormatLocale)}
                        </div>

                        {deltaPrevPercent !== undefined && <div className="relativeTime">
                            {deltaPrev !== undefined && <div className={`pill ${getPill(deltaPrev, props.isLessBetter)} ${getArrow(deltaPrev)}`}>
                                <svg className="svg-icon">
                                    <use xlinkHref="#radix-arrow-top-right" />
                                </svg>
                                {i18n.t("dashboard.prevDelta." + props.xTickFrequency?.toString(), {
                                    delta: Formatter.formatPercent(Math.abs(deltaPrevPercent), 1, 1, session.numberFormatLocale),
                                })}
                            </div>}
                        </div>}
                    </div>

                    {deltaPlanPercent !== undefined && <>
                        <div className="separator" />

                        <div className="cell">
                            <div className="headline">
                                {i18n.t("dashboard.plan")}
                            </div>

                            <div className="absoluteTime">
                                <div className="probe plan" />
                                {Formatter.formatSpecificUnit(scale, props.planValue, 1, " ", session.numberFormatLocale)}
                            </div>

                            <div className="relativeTime">
                                <div className={"pill " + getPill(deltaPlanPercent, props.isLessBetter)}>
                                    {i18n.t("dashboard.deltaToPlan", {
                                        delta: (deltaPlanPercent > 0 ? "+" : "") + Formatter.formatPercent(deltaPlanPercent, 1, 1, session.numberFormatLocale)
                                    })}
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>

            <div className="lineChart">
                {scale !== undefined && <LineGraph
                    xAxisTickFrequency={props.xTickFrequency ?? TimePeriodFrequencies.Month}
                    dataGapFilling={kpiDefinition?.dataGapFilling ?? DataGapFilling.Skip}
                    data={props.values}
                    planData={props.planValues}
                    unitScale={scale}
                    fill={true}
                />}
            </div>
        </>}
    </div>;

    function go(trackingId: string, url: string, settingsUpdate: DeepPartial<SettingsType>) {
        trackEvent({
            category: "shortcuts-dashboard",
            action: "clicked",
            name: trackingId,
        });
        const href = "/projects/" + session.projectId + url;
        if (settingsUpdate) {
            settingsUpdate.history = getPushedHistory(`/projects/${session.projectId}/dashboard`, settings);

            const s = ObjectMerger.mergeObject(settings, settingsUpdate);
            settings.set(s);

            if (url) {
                const prefs = ObjectMerger.mergeObjects([
                    settings,
                    getPreferences(href) ?? {},
                    settingsUpdate
                ]);
                setPreferences(prefs, href);
            }
        }

        if (url)
            navigate(href);
    }

}


/**
 * Give this little helper a value and another value, and it returns
 * the rise in percent
 */
export function getDeltaPercent(value: number | undefined, prevValue: number | undefined): number | undefined {
    if (value === prevValue && value !== undefined)
        return 0;

    if (value === undefined || !prevValue)
        // prevValue undefined or 0
        return undefined;

    const delta = value - prevValue;
    return delta / Math.abs(prevValue);
}

/**
 * Define the color depending on the threshold and whether less is better or not.
 */
function getPill(delta: number | undefined, isLessBetter: boolean | undefined): string {
    if (isLessBetter === undefined)
        return "neutralPill";

    if (delta !== undefined &&
        ((isLessBetter && delta > 0) || (!isLessBetter && delta < 0)))
        return "badPill";

    return "goodPill";
}

function getArrow(delta: number): string {
    if (delta > 0)
        return "arrowUp";
    else if (delta < 0)
        return "arrowDown";

    return "arrowStraight";
}