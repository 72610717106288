import React from "react";
import { SessionType } from "../../../contexts/SessionContext";
import { SettingsType } from "../../../contexts/SettingsContext";
import i18n from "../../../i18n";
import { ALL_OBJECT_INDICATOR, GroupingKeys, groupingKeysPassCompatibleGroups, Log, Node, NodeDetailLevel, NodeRoles } from "../../../models/Dfg";
import { Stats } from "../../../models/Stats";
import { DfgUtils } from "../../../utils/DfgUtils";
import { Formatter } from "../../../utils/Formatter";
import { translateTimeComponentActivityValue } from "../../../utils/GroupingUtils";
import { ProgressProps } from "../../progress/Progress";
import { NodeMarkupGroup, NodeMarkupRow, RowTypes, RowValueTypes, SeparatorTypes } from "./NodeRenderer";
import { get } from "lodash";
import { getMainNodeStat, getMainNodeKpi, getEquipmentNodeKpi } from "../../../utils/MainNodeKpi";
import { KpiPresets, valueStreamMarkupKpis } from "../../../models/KpiTypes";
import { KpiTypes, StatisticTypes } from "../../../models/KpiTypes";
import { BaseQuantityType, EquipmentNodeStatisticsSchema } from "../../../models/ApiTypes";
import { isObjectCentricAvailable } from "../../../utils/SettingsUtils";
import { getCaseKpiValueAndUnit, getKpiDefinition, hasDelaySubtimes, KpiDefinition } from "../../../models/Kpi";
import { CaseAggregationStatistics } from "../../../models/Case";

/**
 * Generates the grouping-dependant node header consisting of the name and group as fits
 */
export function getNodeHeaders(node: Node, isAlwaysDetailed = false) {
    const orderSeqenceInfo = {
        rowType: node.role !== NodeRoles.Inventory ? RowTypes.Text : RowTypes.Hidden,
        value: i18n.t("common.orderSequence") + " " + node.activityValues?.passId?.value ?? "",
    };
    // If the option isAlwaysDetailed is true, we will pass both options into the detailed entry. 
    // This way it does not matter what option is set in the controller and we always have a detailed view.
    const detailedEntry = isAlwaysDetailed ? [NodeDetailLevel.Detailed, NodeDetailLevel.Simple] : [NodeDetailLevel.Detailed];
    return [
        // Grouping: None
        {
            groupingKeys: [GroupingKeys.None],
            elements: [
                {
                    separator: SeparatorTypes.Line,
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: node.activityValues?.timeComponent?.value ? RowTypes.Headline : RowTypes.Hidden,
                        value: translateTimeComponentActivityValue(node.activityValues?.timeComponent?.value) ?? ""
                    }, {
                        separator: SeparatorTypes.Line,
                        rowType: RowTypes.Text,
                        value: node.activityValues?.operation?.value,
                    }, {
                        separator: SeparatorTypes.Line,
                        detailLevels: [NodeDetailLevel.Detailed],
                        rowType: node.activityValues?.machine?.value ? RowTypes.Text : RowTypes.Hidden,
                        value: node.activityValues?.machine?.value,
                    }]
                }
            ]
        },
        {
            groupingKeys: [GroupingKeys.NoneValueStream],
            elements: [
                {
                    separator: SeparatorTypes.Line,
                    elements: [
                        {
                            separator: SeparatorTypes.Line,
                            rowType: node.activityValues?.timeComponent?.value ? RowTypes.Headline : RowTypes.Hidden,
                            value: translateTimeComponentActivityValue(node.activityValues?.timeComponent?.value) ?? ""
                        },
                        {
                            separator: SeparatorTypes.Line,
                            rowType: RowTypes.Text,
                            value: node.activityValues?.operation?.value,
                        },
                        orderSeqenceInfo,
                        {
                            rowType: node.activityValues?.operation?.value === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: node.activityValues?.operation?.value,
                        },
                        {
                            separator: SeparatorTypes.Line,
                            detailLevels: detailedEntry,
                            rowType: node.activityValues?.machine?.value ? RowTypes.Text : RowTypes.Hidden,
                            value: node.activityValues?.machine?.value,
                        }
                    ]
                }
            ]
        },
        // Grouping: Pass Value Stream
        {
            groupingKeys: [GroupingKeys.PassValueStream],
            separator: SeparatorTypes.Line,
            elements: [
                {
                    ...orderSeqenceInfo,
                    rowType: node.role !== NodeRoles.Inventory ? RowTypes.Headline : RowTypes.Hidden,
                },
                {
                    rowType: node.activityValues?.operation?.value === undefined ? RowTypes.Hidden : RowTypes.Text,
                    value: node.activityValues?.operation?.value,
                },
                {
                    rowType: node.activityValues?.machine?.nUnique === undefined ? RowTypes.Hidden : RowTypes.Text,
                    value: i18n.t("common.machineCount", { count: node.activityValues?.machine?.nUnique }).toString(),
                    detailLevels: detailedEntry,
                }
            ]
        },
        // Grouping: Object Type
        {
            groupingKeys: [GroupingKeys.ObjectType],
            elements: [
                {
                    separator: SeparatorTypes.Line,
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: RowTypes.Headline,
                        value: node.activityValues?.objectType?.value ?? "",
                    }]
                }
            ]
        },
        {
            groupingKeys: [GroupingKeys.ObjectTypeValueStream],
            elements: [
                {
                    separator: SeparatorTypes.Line,
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: RowTypes.Headline,
                        value: node.activityValues?.objectType?.value ?? "",
                    }, orderSeqenceInfo, {
                        rowType: node.activityValues?.operation?.value === undefined ? RowTypes.Hidden : RowTypes.Text,
                        value: node.activityValues?.operation?.value,
                    },]
                },
            ]
        },
        {
            groupingKeys: [GroupingKeys.Machine, GroupingKeys.MachineType, GroupingKeys.Location, GroupingKeys.MachineValueStream, GroupingKeys.MachineTypeValueStream, GroupingKeys.LocationValueStream, GroupingKeys.MachineObjectType, GroupingKeys.MachineObjectTypeValueStream],
            elements: [
                // Grouping: MachineType
                {
                    groupingKeys: [GroupingKeys.MachineType],
                    separator: SeparatorTypes.Line,
                    elements: [
                        {
                            rowType: RowTypes.Headline,
                            value: node.activityValues?.machineType?.value ?? node.id,
                        }, {
                            rowType: node.activityValues?.machine?.nUnique === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: i18n.t("common.machineCount", { count: node.activityValues?.machine?.nUnique }).toString(),
                            detailLevels: detailedEntry,
                        }
                    ]
                },
                {
                    groupingKeys: [GroupingKeys.MachineTypeValueStream],
                    separator: SeparatorTypes.Line,
                    elements: [
                        {
                            rowType: RowTypes.Headline,
                            value: node.activityValues?.machineType?.value,
                        },
                        orderSeqenceInfo,
                        {
                            rowType: node.activityValues?.operation?.value === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: node.activityValues?.operation?.value,
                        },
                        {
                            rowType: node.activityValues?.machine?.nUnique === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: i18n.t("common.machineCount", { count: node.activityValues?.machine?.nUnique }).toString(),
                            detailLevels: detailedEntry,
                        }
                    ]
                },
                // Grouping: Machine
                {
                    groupingKeys: [GroupingKeys.Machine, GroupingKeys.MachineObjectType],
                    separator: SeparatorTypes.Line,
                    elements: [
                        {
                            rowType: RowTypes.Headline,
                            value: node.activityValues?.machine?.value ?? node.id,
                        }, {
                            rowType: RowTypes.Text,
                            value: node.activityValues?.machineType?.value ?? node.id,
                            detailLevels: detailedEntry,
                        },
                    ],
                },
                // Grouping: Machine Value Stream
                {
                    groupingKeys: [GroupingKeys.MachineValueStream, GroupingKeys.MachineObjectTypeValueStream],
                    separator: SeparatorTypes.Line,
                    elements: [
                        {
                            rowType: RowTypes.Headline,
                            value: node.activityValues?.machine?.value,
                        }, orderSeqenceInfo, {
                            rowType: node.activityValues?.operation?.value === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: node.activityValues?.operation?.value,
                        }, {
                            rowType: RowTypes.Text,
                            value: node.activityValues?.machineType?.value ?? node.id,
                            detailLevels: detailedEntry,
                        }
                    ]
                },
                // Grouping: Location
                {
                    groupingKeys: [GroupingKeys.Location],
                    separator: SeparatorTypes.Line,
                    elements: [
                        {
                            rowType: RowTypes.Headline,
                            value: node.activityValues?.location?.value ?? node.id,
                        }, {
                            rowType: node.activityValues?.machine?.nUnique === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: i18n.t("common.machineCount", { count: node.activityValues?.machine?.nUnique }).toString(),
                            detailLevels: detailedEntry,
                        }, {
                            rowType: node.activityValues?.machineType?.nUnique === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: i18n.t("common.machineTypeCount", { count: node.activityValues?.machineType?.nUnique }).toString(),
                            detailLevels: detailedEntry,
                        }
                    ],
                },
                // Grouping: Location Value Stream
                {
                    groupingKeys: [GroupingKeys.LocationValueStream],
                    separator: SeparatorTypes.Line,
                    elements: [
                        {
                            rowType: RowTypes.Headline,
                            value: node.activityValues?.location?.value ?? node.id,
                        }, orderSeqenceInfo, {
                            rowType: node.activityValues?.operation?.value === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: node.activityValues?.operation?.value,
                        }, {
                            rowType: node.activityValues?.machine?.nUnique === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: i18n.t("common.machineCount", { count: node.activityValues?.machine?.nUnique }).toString(),
                            detailLevels: detailedEntry,
                        }, {
                            rowType: node.activityValues?.machineType?.nUnique === undefined ? RowTypes.Hidden : RowTypes.Text,
                            value: i18n.t("common.machineTypeCount", { count: node.activityValues?.machineType?.nUnique }).toString(),
                            detailLevels: detailedEntry,
                        }
                    ],
                }
            ]
        }];
}


export function getNodeMarkupOutput(node: Node, settings: SettingsType, session: SessionType, overrides?: OverridesType) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const objectNode = DfgUtils.findObjectNode(node, isObjectCentric, settings.graph.objectType);

    const kpi = getMainNodeKpi(node, settings, session);

    const nodeHasRequiredObject = (node.objects?.map(o => o.type).includes(settings.graph.objectType)) || settings.graph.objectType === ALL_OBJECT_INDICATOR;
    const displayStats = (!isObjectCentric || nodeHasRequiredObject);

    const relativeKpiMap = getRelativeOutputKpiMap(objectNode, settings.quantity);

    const objectLabel = getObjectLabel(node, settings.graph.objectType);

    const nodeStatRowElements = [{
        separator: SeparatorTypes.Spacer,
        elements: [{
            separator: SeparatorTypes.Line,
            rowType: RowTypes.Columns,
            label: kpi.label,
            value: kpi.formattedValue,
        }]
    }
    ];

    const relativePercentBarRows = [{
        kpis: KpiPresets.valueStreamOutputKpis.filter(k => k !== KpiTypes.Frequency),
        rowType: relativeKpiMap?.yield === undefined ? RowTypes.Hidden : RowTypes.Columns,
        label: "common.yield",
        value: asPercent(relativeKpiMap?.yield, 1),
        valueType: RowValueTypes.PercentBar
    }, {
        kpis: KpiPresets.valueStreamOutputKpis.filter(k => k !== KpiTypes.Frequency),
        rowType: relativeKpiMap?.scrap === undefined ? RowTypes.Hidden : RowTypes.Columns,
        label: "common.scrap",
        value: asPercent(relativeKpiMap?.scrap, 1),
        valueType: RowValueTypes.PercentBar
    }];

    if (node.role === NodeRoles.Inventory)
        return filterMarkup(getNodeHeaders(node), settings, overrides);

    const markup: NodeMarkupGroup[] = [
        ...getNodeHeaders(node),
        {
            separator: SeparatorTypes.Line,
            elements: [
                {
                    detailLevels: [NodeDetailLevel.Detailed],
                    rowType: (isObjectCentric && nodeHasRequiredObject && objectLabel) ? RowTypes.Html : RowTypes.Hidden,
                    value: <b className="oneCol">{objectLabel}</b>
                },
                ...(kpi && displayStats ? [
                    {
                        separator: SeparatorTypes.Spacer,
                        elements: nodeStatRowElements
                    },
                    {
                        detailLevels: [NodeDetailLevel.Detailed],
                        separator: SeparatorTypes.Spacer,
                        elements: relativePercentBarRows
                    }
                ] : []),
            ]
        }
    ];

    return filterMarkup(markup, settings, overrides);
}

export function getNodeMarkupStock(node: Node, settings: SettingsType, session: SessionType, overrides?: OverridesType) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const nodeHasRequiredObject = (node.objects?.map(o => o.type).includes(settings.graph.objectType)) || settings.graph.objectType === ALL_OBJECT_INDICATOR;

    const objectLabel = getObjectLabel(node, settings.graph.objectType);

    if (node.role === NodeRoles.Inventory)
        return filterMarkup(getNodeHeaders(node), settings, overrides);

    const kpi = getMainNodeKpi(node, settings, session, { kpiType: settings.kpi.selectedKpi === KpiTypes.WorkInProcessInventory ? KpiTypes.WorkInProgressBeforeEventInventory : KpiTypes.OrderBacklogBeforeEvent });

    const markup: NodeMarkupGroup[] = [
        ...getNodeHeaders(node),
        {
            separator: SeparatorTypes.Line,
            elements: [
                {
                    detailLevels: [NodeDetailLevel.Detailed],
                    rowType: (isObjectCentric && nodeHasRequiredObject && objectLabel) ? RowTypes.Html : RowTypes.Hidden,
                    value: <b className="oneCol">{objectLabel}</b>
                }, {
                    separator: SeparatorTypes.Spacer,
                    elements: [{
                        separator: SeparatorTypes.Spacer,
                        elements: [{
                            separator: SeparatorTypes.Line,
                            rowType: RowTypes.Columns,
                            label: kpi.label,
                            value: kpi.formattedValue,
                        }]
                    }
                    ]
                },
            ]
        }
    ];

    return filterMarkup(markup, settings, overrides);
}

export function getNodeMarkupQuality(node: Node, settings: SettingsType, session: SessionType) {

    if (node.role === NodeRoles.Inventory)
        return filterMarkup(getNodeHeaders(node), settings);

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const objectNode = DfgUtils.findObjectNode(node, isObjectCentric, settings.graph.objectType);

    const relativeKpiMap = getRelativeOutputKpiMap(objectNode, settings.quantity);

    const kpi = getMainNodeKpi(node, settings, session);

    const hasObjectType = (node.objects?.map(o => o.type).includes(settings.graph.objectType)) || settings.graph.objectType === ALL_OBJECT_INDICATOR;
    const displayStats = (!isObjectCentric || hasObjectType);

    const objectLabel = getObjectLabel(node, settings.graph.objectType);

    const statRowElements = [{
        elements: [{
            separator: SeparatorTypes.Line,
            rowType: RowTypes.Columns,
            label: kpi.label,
            value: kpi.formattedValue,
        }]
    }];

    const relativePercentBarRows = [
        {
            kpis: KpiPresets.valueStreamQualityKpis.filter(k => k !== KpiTypes.ScrapRatio),
            rowType: relativeKpiMap?.scrap === undefined ? RowTypes.Hidden : RowTypes.Columns,
            label: "quality.quota",
            value: asPercent(relativeKpiMap?.scrap, 1),
            valueType: RowValueTypes.PercentBar
        }
    ];

    const markup: NodeMarkupGroup[] = [
        ...getNodeHeaders(node),
        {
            separator: SeparatorTypes.Line,
            elements: [
                {
                    detailLevels: [NodeDetailLevel.Detailed],
                    rowType: (isObjectCentric && hasObjectType && objectLabel) ? RowTypes.Html : RowTypes.Hidden,
                    value: <b className="oneCol">{objectLabel}</b>
                },
                ...(displayStats ? [
                    {
                        separator: SeparatorTypes.Spacer,
                        elements: statRowElements
                    },
                    {
                        separator: SeparatorTypes.Spacer,
                        detailLevels: [NodeDetailLevel.Detailed],
                        elements: relativePercentBarRows
                    }
                ] : []),

            ]
        }
    ];

    return filterMarkup(markup, settings);
}

export function getNodeMarkupEnergy(node: Node, settings: SettingsType, session: SessionType) {

    if (node.role === NodeRoles.CarbonScope3) {
        return getMaterialNodeMarkup(settings, session);
    }

    if (node.role === NodeRoles.Inventory)
        return filterMarkup(getNodeHeaders(node), settings);

    const kpi = getMainNodeKpi(node, settings, session);

    const markup: NodeMarkupGroup[] = [
        ...getNodeHeaders(node),
        {
            separator: SeparatorTypes.Line,
            elements: [
                {
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: RowTypes.Columns,
                        label: kpi.label,
                        value: kpi.formattedValue,
                    }]
                },
            ],
        }
    ];

    return filterMarkup(markup, settings);
}

export function getNodeMarkupDeviations(node: Node, settings: SettingsType, session: SessionType) {

    const mainKpi = getMainNodeKpi(node, settings, session);
    const layTimeKpi = getMainNodeKpi(node, settings, session, { kpiType: KpiTypes.ThroughputTime });

    if (node.role === NodeRoles.Inventory) {
        mainKpi.label = i18n.t("common.layTime");
        mainKpi.formattedValue = layTimeKpi.formattedValue;
    }

    // TODO: introduce label differences for confirmations
    const markup: NodeMarkupGroup[] = [
        ...getNodeHeaders(node),

        // Timing: Mean and sum
        {
            kpis: KpiPresets.valueStreamTimeDeviationKpis.filter(k => k !== KpiTypes.Frequency),
            separator: SeparatorTypes.Line,
            elements: [
                {
                    separator: SeparatorTypes.Spacer,
                    elements: [{
                        rowType: RowTypes.Columns,
                        label: mainKpi.value !== undefined ? `${i18n.t("common.scheduleDeviation")} ${i18n.t(mainKpi.label)}` : "workflows.planningDeviation.noData",
                        value: mainKpi.value !== undefined ? mainKpi.formattedValue : ""
                    }]
                }
            ]
        },

        // Frequency
        {
            kpis: [KpiTypes.Frequency],
            separator: SeparatorTypes.Line,
            elements: [
                {
                    separator: SeparatorTypes.Spacer,
                    elements: [{
                        rowType: RowTypes.Columns,
                        label: mainKpi.value !== undefined ? "workflows.planningDeviationByProduct.nodeDeviationFrequency" : "workflows.planningDeviation.noData",
                        value: mainKpi.formattedValue,
                    }]
                }
            ]
        },

        // #endregion
    ];

    return filterMarkup(markup, settings);
}

export type OverridesType = Partial<{
    kpi: KpiTypes,
}>;

export function getNodeMarkupTimings(node: Node, settings: SettingsType, session: SessionType, overrides?: OverridesType) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const nodeHasRequiredObject = node.objects?.some(o => o.type === settings.graph.objectType) || settings.graph.objectType === ALL_OBJECT_INDICATOR;
    const showObjectInformation = isObjectCentric && nodeHasRequiredObject;
    const showCaseInformation = (settings.graph.objectType === undefined || !isObjectCentric) && !showObjectInformation;

    const objectNode = DfgUtils.findObjectNode(node, isObjectCentric, settings.graph.objectType);
    const objectLabel = getObjectLabel(node, settings.graph.objectType);
    const mainNodeKpi = getMainNodeKpi(node, settings, session);
    const busyTimeKpi = getMainNodeKpi(node, settings, session, { kpiType: KpiTypes.BusyTime });
    const layTimeKpi = getMainNodeKpi(node, settings, session, { kpiType: KpiTypes.ThroughputTime });
    layTimeKpi.label = i18n.t("common.layTime");

    // Adds pass / main event time/frequency for cases
    const mainNodeInfo: NodeMarkupRow[] = [{
        detailLevels: [NodeDetailLevel.Simple, NodeDetailLevel.Detailed],
        rowType: RowTypes.Columns,
        label: node.role !== NodeRoles.Inventory ? mainNodeKpi.label : layTimeKpi.label,
        value: node.role !== NodeRoles.Inventory ? mainNodeKpi.formattedValue : layTimeKpi.formattedValue,
    }];

    // Adds busy time/frequency to nodes
    const busyNodeInfo: NodeMarkupRow[] = [{
        kpis: [KpiTypes.ThroughputTime, KpiTypes.BusyTime],
        rowType: RowTypes.Columns,
        label: "common.busy",
        value: busyTimeKpi.formattedValue,
    }, {
        kpis: [KpiTypes.Frequency],
        rowType: RowTypes.Columns,
        label: "common.busy",
        value: Formatter.formatNumber(objectNode.busyFrequencyStatistics?.sum, undefined, session.numberFormatLocale),
    }];

    const markup: NodeMarkupGroup[] = [
        // include headers (Name, additional group information)
        ...getNodeHeaders(node),

        // Object type information
        {
            separator: SeparatorTypes.Line,
            elements: [
                {
                    detailLevels: [NodeDetailLevel.Detailed],
                    separator: SeparatorTypes.Spacer,
                    rowType: (showObjectInformation && objectLabel) ? RowTypes.Html : RowTypes.Hidden,
                    value: <b className="oneCol">{objectLabel}</b>
                },
                // Add busy time/frequency for objects if selected, and cases otherwise.
                ...(showObjectInformation ? mainNodeInfo : (showCaseInformation ? mainNodeInfo : [])),
            ]
        },
        // Detailed information on order sequence sub-times show only if busy time frequency exists
        ...((showObjectInformation || showCaseInformation) && objectNode.busyFrequencyStatistics?.sum && [KpiTypes.ThroughputTime, KpiTypes.BusyTime, KpiTypes.FailureTime, KpiTypes.Frequency].includes(settings.kpi.selectedKpi)) ? [
            {
                separator: SeparatorTypes.Line,
                detailLevels: [NodeDetailLevel.Detailed],
                groupingKeys: groupingKeysPassCompatibleGroups,
                elements: [
                    {
                        elements: [{
                            rowType: RowTypes.Html,
                            value: <div className="oneCol">{i18n.t(groupingKeysPassCompatibleGroups.includes(settings.groupingKey) ? "common.passesWith" : "common.confirmationsWith").toString()}</div>,
                        }],
                        separator: SeparatorTypes.Spacer,
                    },
                    // Add busy time (detailed view)
                    {
                        kpis: [KpiTypes.ThroughputTime, KpiTypes.BusyTime, KpiTypes.Frequency],
                        elements: busyNodeInfo,
                        separator: SeparatorTypes.Spacer
                    },
                    {
                        kpis: hasDelaySubtimes(session) ? [KpiTypes.FailureTime] : [],
                        elements: mainNodeInfo,
                        separator: SeparatorTypes.Spacer
                    },
                    {
                        kpis: [KpiTypes.ThroughputTime, KpiTypes.BusyTime],
                        elements: (objectNode.busyTimeStatistics?.total ? [
                            buildPercentBar("common.production", objectNode.productionTimeStatistics?.total, objectNode.busyTimeStatistics?.total),
                            buildPercentBar("common.setup", objectNode.setupTimeStatistics?.total, objectNode.busyTimeStatistics?.total),
                            buildPercentBar("common.failure", objectNode.failureTimeStatistics?.total, objectNode.busyTimeStatistics?.total),
                            buildPercentBar("common.interruption", objectNode.interruptionTimeStatistics?.total, objectNode.busyTimeStatistics?.total),
                        ] : []),
                    },
                    {
                        kpis: hasDelaySubtimes(session) ? [KpiTypes.FailureTime] : [],
                        elements: (objectNode.failureTimeStatistics?.total ? [
                            buildPercentBar("common.technicalLossesShort", objectNode.technicalLossesTimeStatistics?.total, objectNode.failureTimeStatistics?.total),
                            buildPercentBar("common.organizationalLossesShort", objectNode.organizationalLossesTimeStatistics?.total, objectNode.failureTimeStatistics?.total),
                            buildPercentBar("common.processLossesShort", objectNode.processLossesTimeStatistics?.total, objectNode.failureTimeStatistics?.total),
                            buildPercentBar("common.qualityLossesShort", objectNode.qualityLossesTimeStatistics?.total, objectNode.failureTimeStatistics?.total),

                        ] : []),
                    },
                    {
                        kpis: [KpiTypes.Frequency],
                        elements: [
                            buildFreqBar("common.productionFrequency", objectNode.productionFrequencyStatistics?.sum, objectNode.busyFrequencyStatistics?.sum, session),
                            buildFreqBar("common.setupFrequency", objectNode.setupFrequencyStatistics?.sum, objectNode.busyFrequencyStatistics?.sum, session),
                            buildFreqBar("common.failureFrequency", objectNode.failureFrequencyStatistics?.sum, objectNode.busyFrequencyStatistics?.sum, session),
                            buildFreqBar("common.interruptionFrequency", objectNode.interruptionFrequencyStatistics?.sum, objectNode.busyFrequencyStatistics?.sum, session),
                        ]
                    }
                ]
            }
        ] : []
        // #endregion
    ];

    // Pass change section for frequency (otherwise following here) is left out here since we calculate the
    // "mean" as though every pass has a pause (some of them are 0)
    // Showing a frequency here might be confusing as it does not
    // show the frequency that is used to compute the mean.

    return filterMarkup(markup, settings, overrides);
}

export function getNodeMarkupOrderTracking(node: Node, settings: SettingsType, session: SessionType) {

    // WARNING: The product is currently labeled as "location".
    // In the future, this will most likely no longer be the case as this is a
    // hack to work around incomplete event logs and missing case tables.
    const productElement = {
        separator: SeparatorTypes.Line,
        rowType: node.activityValues?.location?.value ? RowTypes.Text : RowTypes.Hidden,
        value: node.activityValues?.location?.value
    };

    const markup: NodeMarkupGroup[] = [
        // Header
        {
            // Grouping: MachineType (in reality this is the Order)
            groupingKeys: [GroupingKeys.MachineType],
            separator: SeparatorTypes.Line,
            elements: [
                {
                    rowType: RowTypes.Headline,
                    value: node.activityValues?.machineType?.value ?? node.id,
                },
                productElement
            ]
        },
        // Grouping: Machine
        {
            groupingKeys: [GroupingKeys.Machine],
            separator: SeparatorTypes.Line,
            elements: [
                {
                    rowType: RowTypes.Headline,
                    value: node.activityValues?.machine?.value ?? node.id,
                },
                productElement
            ],
        },
        // Statistics
        {
            separator: SeparatorTypes.Line,
            groupingKeys: [GroupingKeys.MachineType],
            elements: [
                {
                    kpis: [KpiTypes.ThroughputTime],
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: RowTypes.Columns,
                        label: i18n.t("common.throughputTime").toString(),
                        value: node.throughputTime !== undefined ? Formatter.formatDurationShort(node.throughputTime, undefined, session.numberFormatLocale) : i18n.t("common.unknownValue") as string,
                    }]
                }, {
                    kpis: [KpiTypes.Frequency],
                    elements: [
                        {
                            separator: SeparatorTypes.Line,
                            rowType: RowTypes.Columns,
                            label: i18n.t("common.frequency").toString(),
                            value: Formatter.formatNumber(node.frequencyStatistics?.sum, undefined, session.numberFormatLocale),
                        }
                    ]
                }
            ],
        },
        {
            separator: SeparatorTypes.Line,
            groupingKeys: [GroupingKeys.Machine],
            elements: [
                {
                    kpis: [KpiTypes.ThroughputTime],
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: RowTypes.Columns,
                        label: i18n.t("common.busyTime").toString(),
                        value: node.busyTimeStatistics?.mean !== undefined ? Formatter.formatDurationShort(node.busyTimeStatistics?.mean, undefined, session.numberFormatLocale) : i18n.t("common.unknownValue") as string
                    }]
                }, {
                    kpis: [KpiTypes.Frequency],
                    elements: [
                        {
                            separator: SeparatorTypes.Line,
                            rowType: RowTypes.Columns,
                            label: i18n.t("common.frequency").toString(),
                            value: Formatter.formatNumber(node.frequencyStatistics?.sum, undefined, session.numberFormatLocale),
                        }]
                }
            ],
        }, {
            separator: SeparatorTypes.Line,
            groupingKeys: [GroupingKeys.Machine, GroupingKeys.MachineType],
            elements: [
                {
                    kpis: [KpiTypes.ScrapRatio],
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: RowTypes.Columns,
                        label: i18n.t("quality.relativeScrap").toString(),
                        // TODO: add other statistics than only count (mass, length)
                        value: node.kpis?.relativeScrapCount !== undefined ? Formatter.formatPercent(node.kpis?.relativeScrapCount, undefined, 1, session.numberFormatLocale) : i18n.t("common.unknownValue") as string
                    }]
                }
            ],
        }
    ];

    return filterMarkup(markup, settings);
}

export function getNodeMarkupBillOfMaterials(node: Node, settings: SettingsType, session: SessionType) {
    const object = node as unknown as CaseAggregationStatistics;
    const goodQuantityKpi = getCaseKpiValueAndUnit(object, KpiTypes.GoodQuantity, StatisticTypes.Sum, settings, session);
    const defaultKpi = getCaseKpiValueAndUnit(object, settings.kpi.selectedKpi, settings.kpi.statistic, settings, session);
    const storageTimeKpi = getCaseKpiValueAndUnit(object, KpiTypes.StorageTime, settings.kpi.statistic, settings, session);
    const shareKpi = getCaseKpiValueAndUnit(object, KpiTypes.ComponentShare, StatisticTypes.Mean, settings, session);

    const nodeHeader = {
        separator: SeparatorTypes.Line,
        elements: [
            {
                rowType: RowTypes.Headline,
                value: node.name ?? node.id,
            }, {
                rowType: node.metadata?.componentPosition?.value ? RowTypes.Text : RowTypes.Hidden,
                value: `${i18n.t("common.position")} ${node.metadata?.componentPosition?.value}`,
            }, {
                rowType: node.metadata?.componentRank?.value ? RowTypes.Text : RowTypes.Hidden,
                value: `${i18n.t("common.rank")} ${node.metadata?.componentRank?.value}`,
            },
        ]
    };

    if (node.role === NodeRoles.Inventory)
        return filterMarkup([
            nodeHeader,
            {
                separator: SeparatorTypes.Line, elements: [{
                    separator: SeparatorTypes.Spacer,
                    elements: [{
                        rowType: RowTypes.Columns,
                        label: storageTimeKpi?.label,
                        value: storageTimeKpi?.formattedValue ?? "-",
                    }]
                }]
            }
        ], settings);

    return filterMarkup([
        // Header
        nodeHeader,

        // Statistics
        {
            separator: SeparatorTypes.Line,
            elements: [
                {
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: defaultKpi?.value !== undefined ? RowTypes.Columns : RowTypes.Hidden,
                        label: defaultKpi?.label,
                        value: defaultKpi?.formattedValue ?? "-",
                    }]
                }
            ],
        },
        {
            separator: SeparatorTypes.Line,
            elements: [
                {
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: goodQuantityKpi?.value !== undefined ? RowTypes.Columns : RowTypes.Hidden,
                        label: goodQuantityKpi?.label,
                        value: goodQuantityKpi?.formattedValue ?? "-",
                    }]
                }
            ],
        },
        {
            separator: SeparatorTypes.Line,
            elements: [
                {
                    elements: [{
                        separator: SeparatorTypes.Line,
                        rowType: shareKpi?.value !== undefined ? RowTypes.Columns : RowTypes.Hidden,
                        label: shareKpi?.label,
                        value: shareKpi?.formattedValue ?? "-",
                    }]
                }
            ],
        },
    ], settings);
}

export function getNodeMarkupValueStream(node: Node, equipmentStats: EquipmentNodeStatisticsSchema[] | undefined, kpiDefinition: KpiDefinition, settings: SettingsType, session: SessionType) {
    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const isEquipmentStats = kpiDefinition.isEquipmentStatsKpi;

    const mainKpi = getNodeKpi(node, equipmentStats, isEquipmentStats, settings, session);

    const stock = getMainNodeKpi(node, settings, session, { kpiType: KpiTypes.WorkInProgressBeforeEventInventory, statistic: StatisticTypes.Mean });

    const showStats = (node.objects?.map(o => o.type).includes(settings.graph.objectType) ||
        settings.graph.objectType === ALL_OBJECT_INDICATOR ||
        settings.graph.objectType === undefined);

    const objectLabel = getObjectLabel(node, settings.graph.objectType);

    if (node.role === NodeRoles.Inventory) {
        const layTimeKpi = getMainNodeKpi(node, settings, session, { kpiType: KpiTypes.ThroughputTime, statistic: StatisticTypes.Mean });
        layTimeKpi.label = i18n.t("common.layTime");
        return filterMarkup([
            ...getNodeHeaders(node, true),
            {
                separator: SeparatorTypes.Line, elements: [{
                    separator: SeparatorTypes.Spacer,
                    elements: [{
                        rowType: RowTypes.Columns,
                        label: layTimeKpi.label,
                        value: layTimeKpi.formattedValue ?? "-",
                    }]
                }]
            }
        ], settings);
    }

    const markup: NodeMarkupGroup[] = [
        ...getNodeHeaders(node, true),
        {
            separator: SeparatorTypes.Line,
            elements: showStats ? [
                {
                    separator: SeparatorTypes.Spacer,
                    elements: [
                        {
                            rowType: (isObjectCentric && showStats && objectLabel) ? RowTypes.Html : RowTypes.Hidden,
                            value: <div className="oneCol"><b>{objectLabel}</b></div>
                        },
                    ]
                }, {
                    separator: SeparatorTypes.Line,
                    elements: [
                        {
                            rowType: RowTypes.Columns,
                            // We use the label defined in valueStreamMarkupKpis if exist (exp:OEE) otherwise we use the label coming from Kpi definition
                            label: valueStreamMarkupKpis.find(kpi => kpi.type === settings.kpi.selectedKpi)?.label ?? mainKpi.label,
                            value: mainKpi.formattedValue ?? "-",
                        }],
                }
            ] : []
        },
        {
            separator: SeparatorTypes.Line,
            elements: [{
                separator: SeparatorTypes.Spacer,
                elements: valueStreamMarkupKpis.filter(kpi => kpi.type !== settings.kpi.selectedKpi && !hideEquipmentStats(kpi.type, session, settings)).map(kpi => {
                    const kpiDefinition = getKpiDefinition(kpi.type, { session, settings });
                    const nodeKpi = getNodeKpi(node, equipmentStats, kpiDefinition?.isEquipmentStatsKpi, settings, session, { kpiType: kpi.type, statistic: kpi.statistic });
                    return {
                        rowType: RowTypes.Columns,
                        label: kpi.label ?? nodeKpi.label,
                        value: nodeKpi.formattedValue,
                    };
                }),
            }]
        },
        {
            separator: SeparatorTypes.Line,
            elements: (showStats && settings.kpi.selectedKpi !== KpiTypes.WorkInProgressBeforeEventInventory) ? [{
                separator: SeparatorTypes.Spacer,
                rowType: RowTypes.Columns,
                label: stock.label,
                value: stock.formattedValue,
            }] : []
        }

    ];

    return filterMarkup(markup, settings);
}

function getNodeKpi(node: Node | undefined, equipmentStats: EquipmentNodeStatisticsSchema[] | undefined, isEquipmentStats: boolean | undefined, settings: SettingsType, session: SessionType, kpi?: { kpiType?: KpiTypes, statistic?: StatisticTypes }) {

    return isEquipmentStats ? getEquipmentNodeKpi(node, equipmentStats, settings, session, kpi) : getMainNodeKpi(node, settings, session, kpi);
}

// Hide the equipment stats kpis if the selected grouping key is different then machine
function hideEquipmentStats(kpi: KpiTypes, session: SessionType, settings: SettingsType) {

    const kpiDefinition = getKpiDefinition(kpi, {session, settings});
    return settings.groupingKey !== GroupingKeys.Machine && settings.groupingKey !== GroupingKeys.MachineValueStream && kpiDefinition?.isEquipmentStatsKpi;
}

export function getCycleTime(node: Node | undefined, settings: SettingsType, session: SessionType) {
    if (node === undefined)
        return;
    
    // FIXME: This hack of using case stats only works if there is only one object in the log. 
    // We should add a check here that this is the case or rely on some project settings to enable it (overlapping).
    // We use the cycle time based on busyTime statistics for objects if it exists. Otherwise we default
    // to the value from the case statistics.
    return getMainNodeStat(node, settings, session, { kpiType: KpiTypes.CycleTime, statistic: settings.kpi.statistic }) ?? getMainNodeStat(node, settings, session, { kpiType: KpiTypes.CycleTime, statistic: settings.kpi.statistic, isObjectCentric: false });
}

export function getTimeStatistics(objectNode: Node | undefined, defaultNode: Node | undefined, timeComponent: "busy" | "production" | "setup" | "failure" | "interruption"): Stats | undefined {
    // we check whether generally busy time statistics are available for the object node
    // in case they are we also try to get the other stats from the object node
    if (objectNode && get(objectNode, "busyFrequencyStatistics.total"))
        return get(objectNode, `${timeComponent}TimeStatistics`) as Stats;

    return get(defaultNode, `${timeComponent}TimeStatistics`) as Stats;

}

export function filterMarkup(markup: (NodeMarkupRow | NodeMarkupGroup)[], settings: SettingsType, overrides?: OverridesType): (NodeMarkupRow | NodeMarkupGroup)[] {
    const mySettings = unwrapOverrides(overrides, settings);

    const constrained = markup.filter(m => (settings.groupingKey === undefined || m.groupingKeys === undefined || m.groupingKeys.indexOf(settings.groupingKey) >= 0) &&
        (settings.graph.nodeDetailLevel === undefined || m.detailLevels === undefined || m.detailLevels.indexOf(settings.graph.nodeDetailLevel) >= 0) &&
        (settings.quantity === undefined || !m.baseQuantities?.length || m.baseQuantities.indexOf(settings.quantity) >= 0) &&
        (mySettings.kpi === undefined || !m.kpis?.length || m.kpis.indexOf(mySettings.kpi) >= 0));

    return constrained.map(m => m.elements === undefined ? m : {
        ...m,
        elements: filterMarkup(m.elements, settings, overrides),
    });
}

function unwrapOverrides(overrides: OverridesType | undefined, settings: SettingsType) {
    return {
        kpi: overrides?.kpi ?? settings.kpi.selectedKpi,
    };
}

function asPercent(stat: number | undefined, of: number | undefined) {
    if (stat === undefined) {
        return;
    }

    const value = stat / (of || 1);
    return Math.max(0, Math.min(100, (value * 100)));
}

type SubOutput = "yield" | "scrap";
type SubOutputKpiMap = { [k in SubOutput]?: number };


export function getRelativeOutputKpiMap(element: Node | Log | undefined, baseQuantity: BaseQuantityType | undefined): SubOutputKpiMap | undefined {
    if (element === undefined)
        return;
    const kpiMap = {
        scrap: {
            mass: element.kpis?.relativeScrapMass,
            count: element.kpis?.relativeScrapCount,
            length: element.kpis?.relativeScrapLength
        },
        yield: {
            mass: element.kpis?.relativeYieldMass,
            count: element.kpis?.relativeYieldCount,
            length: element.kpis?.relativeYieldLength
        }
    };

    return {
        scrap: baseQuantity ? kpiMap.scrap[baseQuantity] : undefined,
        yield: baseQuantity ? kpiMap.yield[baseQuantity] : undefined
    };
}

function buildFreqBar(label: string, count: number | undefined, of: number | undefined, session: SessionType): NodeMarkupRow {
    if (count === undefined || !of)
        return {
            rowType: RowTypes.Hidden,
        };

    return {
        rowType: RowTypes.Columns,
        label: label,
        valueType: RowValueTypes.LabelBar,
        value: {
            percent: (count / of) * 100,
            label: Formatter.formatNumber(count, undefined, session.locale),
        } as ProgressProps
    };
}

/**
 * This function generates the markup for a percent row.
 * @param label label of the bar
 */
function buildPercentBar(label: string, value: number | undefined, of: number | undefined): NodeMarkupRow {
    if (value === undefined || !of)
        return {
            rowType: RowTypes.Hidden,
        };

    const percent = (value * 100) / of;
    const percentClipped = Math.max(0, Math.min(100, percent));

    return {
        rowType: RowTypes.Columns,
        label: label,
        valueType: RowValueTypes.PercentBar,
        value: percentClipped,
    };
}

/**
 * Create material input node for carbon/energy view as mockup.
 * @param settings
 * @param session
 * @returns
 */
function getMaterialNodeMarkup(settings: SettingsType, session: SessionType) {
    const fakeEnergyPerEmission = 2.3;

    const values = (() => {
        const initialValues = [0.62, 0.32, 0.77];
        switch (settings.kpi.selectedKpi) {
            case KpiTypes.Carbon:
                return initialValues.map(v => v * 123).map(v => Formatter.formatWeight(v, 1));
            case KpiTypes.Energy:
                return initialValues.map(v => v * fakeEnergyPerEmission * 123).map(v => Formatter.formatEnergykWh(v, 1, session.locale));
            default:
                break;
        }
    })();

    if (!values)
        return [];

    return [{
        separator: SeparatorTypes.Line,
        elements: [{
            separator: SeparatorTypes.Line,
            elements: [{
                rowType: RowTypes.Headline,
                value: i18n.t("common.material"),
            }]
        }, {
            separator: SeparatorTypes.Section,
            elements: [{
                rowType: RowTypes.Columns,
                label: "A1037829",
                value: values[0],
            }, {
                rowType: RowTypes.Columns,
                label: "A9163923",
                value: values[1],
            }, {
                rowType: RowTypes.Columns,
                label: "A9873819",
                value: values[2],
            }]
        }],
    }];
}

export function getNodeWarnings(node: Node, settings: SettingsType, session: SessionType) {
    const result: string[] = [];

    if (node.role === NodeRoles.Inventory)
        return [];

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);
    const objectNode = DfgUtils.findObjectNode(node, isObjectCentric, settings.graph.objectType);

    const hasYield = (objectNode.yieldCountStatistics?.sum || 0) > 0 ||
        (objectNode.yieldLengthStatistics?.sum || 0) > 0 ||
        (objectNode.yieldMassStatistics?.sum || 0) > 0;

    if (objectNode.activityValues?.passId?.nUnique &&
        objectNode.activityValues?.passId?.nUnique > 1) {
        result.push(i18n.t("dataQualityWarnings.node.multiplePassIds", { count: objectNode.activityValues.passId.nUnique }).toString());
    }

    if ([GroupingKeys.Machine, GroupingKeys.MachineValueStream].includes(settings.groupingKey)) {

        if (hasYield) {
            if (objectNode.productionTimeStatistics?.total === 0)
                // No time was spent producing, but we produced something!
                result.push(i18n.t("dataQualityWarnings.node.yieldWithoutProductionTime").toString());

            if (objectNode.busyTimeStatistics?.total === 0)
                // Yield without busy time
                result.push(i18n.t("dataQualityWarnings.node.yieldWithoutBusyTime").toString());

            if (objectNode.productionTimeStatistics?.total === 0 && settings.kpi.selectedKpi === KpiTypes.ThroughputRate)
                // For yield but no production time there will be no throughput get displayed
                result.push(i18n.t("dataQualityWarnings.node.noThroughputCalculationPossible").toString());
        }

    }

    return result;
}

function getObjectLabel(node: Node, objectType?: string): string | undefined {
    if (objectType === ALL_OBJECT_INDICATOR)
        return node.objects?.length ? node.objects[0].type : "";
    // For Gebinde we currently show the case stats and will not be showing the object information.
    if (objectType === "Gebinde")
        return undefined;
    return objectType;
}
