import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { capitalize, intersection } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { BaseQuantityType, DeviationOptions, disableAllCalcOptions, GetCaseDeviationResult, GetDeviationRequest, RcaDrilldownType, RcatypeTargets } from "../../models/ApiTypes";
import Spinner from "../../components/spinner/Spinner";
import Toast, { ToastTypes } from "../../components/toast/Toast";
import { LegacyAnalyzedValues, RcaType } from "../../contexts/ContextTypes";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext, getRecentRcaByType } from "../../contexts/SettingsContext";
import { ApiHookOptions, useStatistics } from "../../hooks/UseStatistics";
import i18n from "../../i18n";
import { Formatter } from "../../utils/Formatter";
import { QuantityType, getAssignedQuantities, getQuantity, quantities } from "../../utils/Quantities";
import { CreateBottleneckRca } from "./CreateBottleneckRca";
import { FormatterType, StartRcaButton, minCasesForAnalysis, submitRca } from "./RcaCreationUtils";
import { BackButtonTrayElement } from "../../components/tray/BackButtonTrayElement";
import { SortOrder } from "../../models/KpiTypes";
import { CreateOrgLossesRca } from "./CreateOrgLossesRca";
import { useApi } from "../../hooks/UseApi";
import { EventFilter } from "../../models/EventFilter";
import { EventKeys } from "../../models/EventKeys";
import { Datastores } from "../../utils/Datastores";

export default function CreateRootCauseAnalysis(props: {
    type: RcaType,
    title: string;

    /** Must be set if you're after a process path RCA */
    analyzedValues?: LegacyAnalyzedValues;
    baseQuantities?: BaseQuantityType[];
    drilldown?: RcaDrilldownType;
}) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const { trackEvent } = useMatomo();

    const rca = getRecentRcaByType(props.type, settings);

    useEffect(() => {
        if (rca?.sortOrder !== undefined && settings.rca.sortOrder !== rca.sortOrder)
            settings.mergeSet({ rca: { sortOrder: rca.sortOrder } });
    }, []);

    const [stats, areStatsLoading] = useStatistics();
    const [fastCases, areFastCasesLoading] = useCaseDeviation({ calculateTimeAndFreqStats: true, filterCaseDurationDeviations: "early", limit: 0 }, { disable: !(props.type === RcaType.Time) });
    const [slowCases, areSlowCasesLoading] = useCaseDeviation({ calculateTimeAndFreqStats: true, filterCaseDurationDeviations: "late", limit: 0 }, { disable: !(props.type === RcaType.Time) });

    const isViewInitialized = stats.numFilteredTraces !== undefined && !areStatsLoading &&
        !(props.type === RcaType.Time && (slowCases === undefined || areSlowCasesLoading || fastCases === undefined || areFastCasesLoading));

    const relevantCaseCount = getRelevantCaseCount();
    const caseYieldQuantities = getAssignedQuantities(session.project?.eventKeys, QuantityType.CaseYield, false);
    const canStartNumCases = props.type === RcaType.Bottleneck ||
        relevantCaseCount && relevantCaseCount >= minCasesForAnalysis;

    const canStartQuantity = ![RcaType.Bottleneck, RcaType.Throughput].includes(props.type) ||
        caseYieldQuantities.some(q => q.baseQuantity === settings.quantity);

    // If this property is false, the "Start RCA" button is disabled. We want to prevent the user
    // from starting an process path RCA when the settings.quantity property is not
    // set.
    const processPathRcaSettingsInvalid = [RcaType.Throughput, RcaType.ThroughputTime].includes(props.type) &&
        (props.analyzedValues === undefined ||
            (props.analyzedValues === LegacyAnalyzedValues.OutputRate && !(props.baseQuantities as (string[] | undefined) ?? []).includes(settings.quantity as string)));

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    // Special handling for creation of quality RCAs
    const plannedScrapQuantities = getAssignedQuantities(session.project?.eventKeysPlan, [QuantityType.CaseScrap, QuantityType.Scrap], false).map(q => q.baseQuantity);
    const actualScrapQuantities = getAssignedQuantities(session.project?.eventKeys, [QuantityType.CaseScrap, QuantityType.Scrap], false).map(q => q.baseQuantity);
    const scrapQuantitiesIntersection = intersection(plannedScrapQuantities, actualScrapQuantities);
    const showPlanningDataOption = scrapQuantitiesIntersection.length > 0;
    const isPlanningDataOptionDisabled = !scrapQuantitiesIntersection.some(q => q === settings.quantity);

    useEffect(() => {
        const usePlanningData = rca?.targetType?.startsWith("deviation.caseScrap") ?? showPlanningDataOption;
        settings.mergeSet({ rca: { usePlanningData } });
    }, []);

    useEffect(() => {
        const newSettings = JSON.parse(JSON.stringify(settings));

        if (props.type === RcaType.Quality)
            if (props.baseQuantities?.length && !props.baseQuantities.some(q => q === settings.quantity))
                newSettings.quantity = props.baseQuantities[0];

        if (rca.analyzedValue !== undefined)
            newSettings.kpi.analyzedValue = rca.analyzedValue;

        if (rca.quantity !== undefined)
            newSettings.quantity = rca.quantity;

        settings.set(newSettings);
    }, []);

    return <>
        <Spinner isLoading={isSubmitting} />
        <div className="rootCauseAnalysis">
            {props.type === RcaType.Bottleneck && <CreateBottleneckRca />}
            {props.type === RcaType.OrgLosses && <CreateOrgLossesRca />}
            {props.type !== RcaType.Bottleneck &&
                props.type !== RcaType.OrgLosses && <div className="rcaContent">
                <h1 className="mbl">{i18n.t(props.title)}</h1>

                {props.type === RcaType.Time && <div className="rcaSettings">
                    <div className="settings">
                        <label>{i18n.t("rca.analyzeDeviation") + ":"}</label>
                        <div className="gapSmall">
                            <button
                                className={settings.rca.sortOrder === SortOrder.Descending ? "active" : undefined}
                                onClick={() => {
                                    settings.mergeSet({ rca: { sortOrder: SortOrder.Descending } });
                                }}>
                                {i18n.t("rca.analyzeTooSlow")}
                            </button>
                            <button
                                className={settings.rca.sortOrder === SortOrder.Ascending ? "active" : undefined}
                                onClick={() => {
                                    settings.mergeSet({ rca: { sortOrder: SortOrder.Ascending } });
                                }}>
                                {i18n.t("rca.analyzeTooFast")}
                            </button>
                        </div>
                    </div>
                </div>}

                {props.type === RcaType.Quality && <div className="rcaSettings">
                    <div className="settings">
                        <label>{i18n.t("workflows.qualityDeviation.analyzeValue")}:</label>
                        <div className="gapSmall">
                            <button
                                onClick={() => { settings.setKpiState({ analyzedValue: LegacyAnalyzedValues.QualityValuesSum }); }}
                                className={settings.kpi.analyzedValue === LegacyAnalyzedValues.QualityValuesSum ? "active" : ""}>
                                {i18n.t("quality.valuesShort") + " " + i18n.t("common.statistics.shortSum")}
                            </button>

                            <button
                                onClick={() => { settings.setKpiState({ analyzedValue: LegacyAnalyzedValues.QualityQuota }); }}
                                className={settings.kpi.analyzedValue === LegacyAnalyzedValues.QualityQuota ? "active" : ""}>
                                {i18n.t("quality.quota") + " " + i18n.t("common.statistics.shortMean")}
                            </button>
                        </div>
                        {showPlanningDataOption && <>
                            <label />
                            <div>
                                <label className={isPlanningDataOptionDisabled ? "disabled" : ""}>
                                    <input
                                        disabled={isPlanningDataOptionDisabled}
                                        type="checkbox"
                                        className="checkbox"
                                        checked={settings.rca.usePlanningData}
                                        id="checkbox-use-planning"
                                        onChange={(e) => {
                                            settings.mergeSet({ rca: { usePlanningData: e.target.checked } });
                                        }} />
                                    <label htmlFor="checkbox-use-planning" />
                                    {i18n.t("rca.usePlanningData")}
                                </label>
                            </div>
                        </>}
                        <label>{i18n.t("common.quantityUnit")} {i18n.t("common.scrap")}:</label>
                        <div className="gapSmall">
                            {(props.baseQuantities ?? []).map(q =>
                                <button
                                    key={q}
                                    className={settings.quantity === q ? "active" : undefined}
                                    onClick={() => {
                                        settings.set({
                                            quantity: q
                                        });
                                    }}>
                                    {i18n.t("quantities." + q)}
                                </button>)}
                        </div>
                    </div>
                </div>}

                {(props.type === RcaType.Throughput || props.type === RcaType.ThroughputTime) && <div className="rcaSettings">
                    <div className="settings constrainWidth">
                        {props.analyzedValues === LegacyAnalyzedValues.TimeValuesMean && <>
                            <label>
                                {i18n.t("common.kpi")}:
                            </label>
                            <div>
                                <button className="rcaLongDuration active">
                                    {i18n.t("workflows.processPath.rcaLongDuration")}
                                </button>
                            </div>
                        </>}
                        {props.analyzedValues === LegacyAnalyzedValues.OutputRate && <>
                            <label>
                                {i18n.t("common.kpi")}:
                            </label>
                            <div>
                                <button className="rcaLowThroughput active">
                                    {i18n.t("workflows.processPath.rcaLowThroughput")}
                                </button>
                            </div>

                            <label>{i18n.t("common.quantityUnit")}</label>

                            <div className="gapSmall">
                                {caseYieldQuantities.map(q => q.baseQuantity).map(q => {
                                    return <button
                                        key={q}
                                        onClick={() => {
                                            settings.set({ quantity: q });
                                        }}
                                        className={settings.quantity === q ? "active" : undefined}>
                                        {i18n.t("quantities." + q)}
                                    </button>;
                                })}
                            </div>

                        </>}
                    </div>
                </div>}

                <Toast className="mt" visible={!canStartNumCases && isViewInitialized} type={ToastTypes.Info}>
                    {i18n.t("rca.tooFewCases", {
                        minCases: minCasesForAnalysis,
                        caseCount: relevantCaseCount
                    })}
                </Toast>

                <StartRcaButton
                    type={props.type}
                    disabled={(!canStartNumCases || !canStartQuantity || processPathRcaSettingsInvalid) || !isViewInitialized}
                    onClick={() => {
                        if (props.type === RcaType.Time)
                            submitRcaPlanningDeviations(`/projects/${session.projectId}/analyses/rca/deviation`);

                        if (props.type === RcaType.Quality)
                            submitRcaQuality(`/projects/${session.projectId}/analyses/rca/quality`);

                        if (props.type === RcaType.Throughput)
                            submitRcaProcessPath(`/projects/${session.projectId}/analyses/rca/throughput`);

                        if (props.type === RcaType.ThroughputTime)
                            submitRcaProcessPath(`/projects/${session.projectId}/analyses/rca/throughput-time`);
                    }}
                />
            </div>}
        </div>
        <BackButtonTrayElement />
    </>;

    function getRelevantCaseCount() {
        if (props.type === RcaType.Time)
            if (settings.rca.sortOrder === SortOrder.Descending)
                return slowCases?.log.count;
        if (settings.rca.sortOrder === SortOrder.Ascending)
            return fastCases?.log.count;
        return stats.numFilteredTraces;
    }

    async function submitRcaQuality(resultsUrl: string) {
        if (!session?.project?.eventKeys || !session?.eventUpload?.id)
            return;

        const quantity = settings.quantity;

        if (settings.kpi.analyzedValue === LegacyAnalyzedValues.QualityValuesSum) {
            // Select target depending on whether scrap or caseScrap is labelled in the planning data
            const target: RcatypeTargets = !(showPlanningDataOption && settings.rca.usePlanningData) ?
                "caseScrap" + capitalize(quantity) as RcatypeTargets :
                "deviation.caseScrap" + capitalize(quantity) as RcatypeTargets;

            await submitRcaInternal(
                target,
                true,
                LegacyAnalyzedValues.QualityValuesSum,
                resultsUrl,
                (value: number | undefined, locale: string) => {
                    const formatter = getQuantity(quantity, false)?.formatter;
                    if (formatter)
                        return formatter(value, 1, locale);
                    return "";
                },
            );
        }

        if (settings.kpi.analyzedValue === LegacyAnalyzedValues.QualityQuota) {
            const target: RcatypeTargets = !(showPlanningDataOption && settings.rca.usePlanningData) ?
                "kpis.relativeCaseScrap" + capitalize(quantity) as RcatypeTargets :
                "deviation.kpis.relativeCaseScrap" + capitalize(quantity) as RcatypeTargets;

            await submitRcaInternal(
                target,
                true,
                LegacyAnalyzedValues.QualityQuota,
                resultsUrl,
                (value: number | undefined, locale: string) => {
                    return Formatter.formatPercent(value, undefined, 1, locale);
                },
            );
        }
    }

    async function submitRcaPlanningDeviations(resultsUrl: string) {
        await submitRcaInternal(
            "deviation.duration",
            settings.rca.sortOrder === SortOrder.Descending,
            settings.kpi.analyzedValue,
            resultsUrl,
            (value: number | undefined, locale: string) => {
                return Formatter.formatDurationShort(value, undefined, locale);
            },
        );
    }

    async function submitRcaProcessPath(resultsUrl: string) {
        if (!session?.eventUpload || !session.project?.eventKeys)
            return;

        switch (props.analyzedValues) {
            case LegacyAnalyzedValues.TimeValuesMean:
                await submitRcaInternal("duration",
                    true,
                    LegacyAnalyzedValues.TimeValuesMean,
                    resultsUrl,
                    (value: number | undefined, locale: string) => {
                        return Formatter.formatDurationShort(value, undefined, locale);
                    },
                );

                break;

            case LegacyAnalyzedValues.OutputRate: {
                const quantity = quantities.find(q => q.id === settings.quantity);
                const key = "kpis.caseYieldRate" + capitalize(quantity?.baseQuantity);
                await submitRcaInternal(key as RcatypeTargets,
                    false,
                    LegacyAnalyzedValues.OutputRate,
                    resultsUrl,
                    (value: number | undefined, locale: string) => {
                        const formatter = getQuantity(settings.quantity, true)?.formatter;
                        if (formatter)
                            return formatter(value, undefined, locale);
                        return "";
                    },
                );
                break;
            }
        }
    }

    async function submitRcaInternal(targetType: RcatypeTargets, maximize: boolean, analyzedValue: LegacyAnalyzedValues, resultsUrl: string, formatter: FormatterType) {
        setIsSubmitting(true);

        try {
            submitRca(
                session,
                settings,
                props.type,
                props.drilldown,
                targetType,
                maximize,
                analyzedValue,
                settings.filters,
                resultsUrl,
                stats.numFilteredTraces ?? 0,
                formatter,
                settings.rca.sortOrder,
                trackEvent,
                false,
            );
        } catch {
            // do nothing
        } finally {
            setIsSubmitting(false);
        }
    }
}


/**
 * This is the code for the legacy useCaseDeviation hook. It is replaced by the useCases
 * hook, which provides a unified interface for both legacy and new endpoints.
 * However, the filter for early/late cases does not exist in newer endpoints, so this
 * hook is still used here.
 * @deprecated use useCases instead
 */
function useCaseDeviation(request: { eventFilters?: EventFilter[] } & Partial<DeviationOptions>, options?: ApiHookOptions<GetCaseDeviationResult>): [GetCaseDeviationResult | undefined, boolean] {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);

    const currentFilters = (request?.eventFilters as (EventFilter[] | undefined)) ?? settings.previewFilters ?? settings.filters;
    const requestOptions: GetDeviationRequest = {
        // Project may be undefined during initialization. We're checking for that
        // later and disable the useApi hook if so
        actual: {
            uploadId: session.project?.uploadId ?? "",
            eventKeys: session.project?.eventKeys ?? {} as EventKeys,
            eventFilters: currentFilters,
            uploads: session.project?.uploads,
        },
        planned: {
            uploadId: session.project?.uploadIdPlan ?? "",
            eventKeys: session.project?.eventKeysPlan ?? {} as EventKeys,
        },
        consolidatePasses: true,
        ...disableAllCalcOptions,
        ...request,
    };

    return useApi(
        Datastores.getPerCaseDeviationStatistics,
        requestOptions,
        [
            JSON.stringify(requestOptions)
        ], {
            ...options,
            disable: options?.disable || 
                session.project === undefined || 
                session.project.uploadIdPlan === undefined || 
                session.project.eventKeysPlan === undefined,
        });
}
