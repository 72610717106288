import React, { useContext, useMemo } from "react";
import GanttChart, { GanttElement } from "../../components/gantt-chart/GanttChart";
import Shortcuts, { ShortcutContexts } from "../../components/shortcuts/Shortcuts";
import Spinner from "../../components/spinner/Spinner";
import { SessionContext } from "../../contexts/SessionContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { useCaseGantt } from "../../hooks/UseCaseGantt";
import i18n from "../../i18n";
import { CaseGanttStats, formatCaseStat, getStatType } from "../../models/Gantt";
import useWindowResize from "../../utils/WindowResizeHook";
import { defaultGanttColor, getFilterStartEndTimes, getStatistics } from "./CommonGantt";
import { getGanttColumnHeaders, getTooltipByGroupingKeySelected } from "./ProcessGantt";
import { useGraph } from "../../hooks/UseGraph";
import { NodeActivitySchema } from "../../models/Dfg";
import { valueStreamGroupingKeys } from "../../components/controls/GroupingKeyControls";
import { CaseGanttCase, CaseGanttSetting, TimeMode } from "../../models/ApiTypes";
import { ALL_NODES } from "../../components/controls/NodeSelectionControls";
import { buildNodeFilter } from "../../utils/FilterBuilder";

type UserData = {
    activity?: string;
    activityValues?: NodeActivitySchema;
    count?: number;
    startDate: number,
    endDate: number,
    row: CaseGanttCase,
}

/**
 * List of colors that match the ONIQ brand.
 * It is an alternating list of colors in blue, green, purple and grey tones.
 */
const distinctColormap = [
    "#08DFD2", "#00958C", "#4BFFF4", "#B5FFFB",
    "#B8D0FF", "#78A6FF", "#854BFF", "#AB83FE",
    "#6B25FE", "#666666", "#42516A", "#D7C3FF"
];

/**
 * Pick a color from the colormap based on the index.
 * @param index 
 * @returns 
 */
function pickColor(index: number): string {
    const colorIndex = index % distinctColormap.length;
    return distinctColormap[colorIndex];
}

export default function CaseGantt() {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const graph = useGraph({
        calculateActivityValues: true,
        calculateNodes: true,
    });

    const isRestrictedNode = settings.gantt.restrictToNode !== ALL_NODES && settings.gantt.restrictToNode !== undefined;

    const restrictedNode = isRestrictedNode ? graph?.nodes.find(n => n.id === settings.gantt.restrictToNode) : undefined;
    const addNodeFilter = isRestrictedNode && restrictedNode !== undefined ? buildNodeFilter(restrictedNode, false, settings.groupingKey) : undefined;

    const [gantt, isLoading] = useCaseGantt(
        { eventFilters: addNodeFilter ? [...(settings.previewFilters ?? settings.filters), addNodeFilter] : (settings.previewFilters ?? settings.filters) },
    );

    useWindowResize();

    const filterStartEndTimes = (settings.gantt.timeMode === TimeMode.Absolute && settings.gantt.restrictToTimeFilter) ? getFilterStartEndTimes(settings.previewFilters ?? settings.filters) : undefined;
    const statistics = getStatistics(gantt?.cases, filterStartEndTimes);
    const { startTime, tickInterval, columnHeadersMarkup } = getGanttColumnHeaders(statistics, settings, session);
    const filteredCases: CaseGanttCase[] = (gantt?.cases ?? []).map((row) => {
        const events = row.events.filter(e => {
            if (filterStartEndTimes?.minTime !== undefined && +e[1] < filterStartEndTimes?.minTime)
                return false;
            if (filterStartEndTimes?.maxTime !== undefined && +e[0] > filterStartEndTimes?.maxTime)
                return false;
            const activity = typeof e[2] === "object" ? gantt?.activities[e[2][0]] : undefined;
            return activity !== undefined && (settings.gantt.restrictToNode === undefined || settings.gantt.restrictToNode === ALL_NODES || settings.gantt.restrictToNode === activity);
        });
        return { ...row, events };
    }).filter(c => c.events.length > 0);

    if (settings.gantt.caseGanttSettings === CaseGanttSetting.StartTime)
        filteredCases.sort((a, b) => {
            {
                if (settings.gantt.sortOrder === "ascending")
                    return +a.events[0][0] - +b.events[0][0];
                else
                    return +b.events[0][0] - +a.events[0][0];
            }
        });

    const data = useMemo(() => {
        const result: GanttElement<UserData>[] = [];

        // Render content bars
        (filteredCases ?? []).forEach((row, rowIdx) => {
            row.events.forEach((interval) => {
                const intervalStart = +interval[0];
                const intervalEnd = +interval[1];
                const activity = typeof interval[2] === "object" ? gantt?.activities[interval[2][0]] : undefined;
                const activityValues = graph?.nodes.find(n => n.id === activity)?.activityValues;
                const element: GanttElement<UserData> = {
                    rowIndex: rowIdx,
                    start: (intervalStart - startTime) / (tickInterval.seconds),
                    end: intervalEnd ? (intervalEnd - startTime) / (tickInterval.seconds) : undefined,
                    color: isRestrictedNode ? defaultGanttColor :
                        typeof interval[2] === "object" ? pickColor(interval[2][0]) : undefined,
                    data: {
                        startDate: intervalStart,
                        endDate: intervalEnd,
                        count: typeof interval[2] === "number" ? interval[2] : undefined,
                        activity: activity,
                        activityValues: activityValues,
                        row,
                    }
                };

                result.push(element);
            });
        });
        return result;
    }, [
        graph,
        filteredCases,
        session.locale,
        settings.gantt.restrictToTimeFilter,
        settings.gantt.restrictToNode
    ]);

    const statType = getStatType(settings);
    const rowHeaders = useMemo(() => {
        return (filteredCases ?? []).map(c => {
            return <div className="rowHeaderElements" key={c.caseId}>
                <div>{formatCaseStat(c, statType, session)}</div>
                <div>{c.caseId?.toString() ?? ""}</div>
            </div>;
        });
    }, [
        filteredCases,
        session.locale,
        settings.gantt.caseGanttSettings,
    ]);

    const isValueStreamGrouping = valueStreamGroupingKeys.includes(settings.groupingKey);

    const selectedRow = useMemo(() => {
        return (data ?? []).find(d => d.data?.row.caseId === settings.selection.case)?.rowIndex;
    }, [
        settings.selection.case,
        data,
    ]);

    const topLabel = <div className="topLabel">
        <div>{i18n.t(CaseGanttStats.find((c) => c.statType === statType)?.label ?? "")}</div>
        <div>{i18n.t("common.caseId")}</div>
    </div>;

    if (isLoading || gantt === undefined)
        return <Spinner isLoading={true} showProjectLoadingSpinner={true} />;

    return <div className="ganttExplorer">
        <div className="ganttContainer">
            <GanttChart
                data={data}
                columnHeaderHeight={settings.gantt.timeMode === TimeMode.Absolute ? 55 : 35}
                topLabel={topLabel}
                columnHeader={columnHeadersMarkup}
                rowHeader={rowHeaders}
                selectedRow={selectedRow}
                onRowClicked={(rowIdx) => {
                    if (!gantt)
                        return;

                    const caseId = data.find(d => d.rowIndex === rowIdx)?.data?.row.caseId;

                    if (settings.selection.case === caseId)
                        settings.setSelection({});
                    else
                        settings.setSelection({ case: caseId });
                }}
                tooltipHandler={(element) => {
                    return getTooltipByGroupingKeySelected(settings.groupingKey, element, isValueStreamGrouping);
                }}
            />
        </div>
        <Shortcuts stack={true} handledSelections={[ShortcutContexts.Case]} />
    </div>;
}
