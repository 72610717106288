import React, { useContext, useMemo, useRef } from "react";
import { ContainerModes, GraphLine, commonSelectionLineProps } from "../graph/GraphCommon";
import { Bar, GroupGraph } from "../graph/GroupGraph";
import Spinner from "../spinner/Spinner";
import { SessionContext, SessionType, hasDownloadPermission } from "../../contexts/SessionContext";
import { SettingsContext, SettingsType, SortByType } from "../../contexts/SettingsContext";
import i18n from "../../i18n";
import { analysisGraphMapping, AnalysisType, useGraph } from "../../hooks/UseGraph";
import { getShortActivityLabelFromActivityValues, groupKeyToActivityKeys, translateActivityKey } from "../../utils/GroupingUtils";
import { ALL_OBJECT_INDICATOR, BaseGraph, Edge, GroupingKeys, Node, NodeRoles } from "../../models/Dfg";
import Shortcuts, { ShortcutContexts } from "../shortcuts/Shortcuts";
import { getKpiDefinition, getUnit } from "../../models/Kpi";
import { UnitMetadata } from "../../utils/Formatter";
import { useGridResize } from "./UseGridResize";
import { getCycleTime } from "../dfg/nodes/NodeMarkupFactory";
import colors from "../../colors.json";
import { useCustomerTakt } from "../../hooks/UseProductionTakt";
import { getMainNodeStat } from "../../utils/MainNodeKpi";
import { getCustomKpisDfg, getNodeKpiStatistics } from "../../utils/DfgUtils";
import DownloadFile, { TemplateType } from "../download-file/DownloadFile";
import { valueStreamGroupingKeys } from "../controls/GroupingKeyControls";
import { KpiComparisons } from "../../contexts/ContextTypes";
import { getAnalysisTitle, getAxisLabel, getBarColor } from "../product-chart/ProductChart";
import { getLegend } from "../../views/process-kpi-chart/ProductProcessKpiChart";
import { KpiTypes, SortOrder } from "../../models/KpiTypes";
import { BaseQuantityType } from "../../models/ApiTypes";
import { getDefaultEnabledComparisons, isObjectCentricAvailable } from "../../utils/SettingsUtils";
import { useUnifiedDeviationGraphs } from "../../hooks/UseUnifiedDeviationGraph";
import { addAverageStockToGraphNodes } from "../../utils/AverageStock";

export type NodeKpiChartProps = {
    analysisType: AnalysisType;
    noDataPlaceholder: string;
    pageSlug: string;
    title?: string;
    className?: string;
    disableShortcuts?: boolean;
    enabledComparisons?: KpiComparisons[];
}

export function NodeKpiChart(props: NodeKpiChartProps) {
    const session = useContext(SessionContext);
    const settings = useContext(SettingsContext);
    const horizonalLines: GraphLine[] = [];

    const isObjectCentric = isObjectCentricAvailable(session.project?.eventKeys);

    const analysisArguments = analysisGraphMapping.find((a) => a.analysisType === props.analysisType)?.arguments;

    const kpiDefinition = getKpiDefinition(settings.kpi.selectedKpi, { session, settings });
    const isPlanningComparison = settings.kpi.comparisons === KpiComparisons.Planning && kpiDefinition?.allowedComparisons.includes(KpiComparisons.Planning);

    const graphOptions = {
        ...analysisArguments,
        ...getCustomKpisDfg(settings, session, false),
        calculateNodes: true,
        calculateEdges: true,
        calculateRoles: true,
        calculateActivityValues: true,
    };

    // In case an edge is selected, deselect it, because this chart cannot handle that. For that
    // reason we also do not need to update the selected entity if that entity happens to be anything
    // other than a node.
    React.useEffect(() => {
        if (settings.selection.edge)
            settings.setSelection({});
    }, []);

    // Deviation graph data is only needed when data should be compared to the planning data
    const graphOptionsPlan = {
        ...analysisArguments,
        ...getCustomKpisDfg(settings, session, true),
        calculateNodes: true,
        calculateActivityValues: true,
        calculateRoles: true,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [actualDeviation, planned, _, isDeviationGraphLoading] = useUnifiedDeviationGraphs(graphOptionsPlan, { disable: !isPlanningComparison });
    const graph = useGraph(graphOptions, props.analysisType, settings.selection.node !== undefined, isPlanningComparison);

    const actual = isPlanningComparison ? actualDeviation : graph;
    const isLoading = isPlanningComparison ? isDeviationGraphLoading : graph === undefined;

    if (props.analysisType === AnalysisType.Output ||
        props.analysisType === AnalysisType.Stock) {
        if (actual !== undefined)
            addAverageStockToGraphNodes(actual);
        if (planned !== undefined)
            addAverageStockToGraphNodes(planned);
    }

    // For the cycle time chart we calculate the production takt as a referende value and we draw a red horizontal line.
    // We only want to show it for the PassValueStream grouping because it may be wrong for other groupings.
    const productionTakt = useCustomerTakt(settings.kpi.selectedKpi !== KpiTypes.CycleTime);
    if (productionTakt !== undefined && settings.kpi.showCustomerTakt && settings.groupingKey === GroupingKeys.PassValueStream)
        horizonalLines.push({ ...productionTakt, color: colors.$coral, width: 2 });

    const container = useRef<HTMLDivElement>(null);
    const [width, height] = useGridResize(container, 1, undefined, undefined, 0);

    const className = props.className ?? "fillParentMargin";

    const isComparisonHighlightingEnabled = settings.kpi.comparisons === KpiComparisons.Planning && settings.kpi.highlightDeviations;

    const data: Bar<Node>[][] = useMemo(() => {
        if (actual === undefined)
            return [];


        const isComparisonAllowed = (props.enabledComparisons ?? getDefaultEnabledComparisons(session, settings))?.includes(settings.kpi.comparisons) ?? false;

        const result: Bar<Node>[][] = [];
        const relevantNodes = actual.nodes !== undefined ? getRelevantNodes(actual.nodes, isObjectCentric, settings.graph.objectType) : [];
        const plannedNodeMap = new Map<string, Node>(planned?.nodes?.map(n => [n.id, n]) ?? []);
        for (const node of relevantNodes) {
            if (node === undefined || 
                node.role === NodeRoles.Start || 
                node.role === NodeRoles.End || 
                node.role === NodeRoles.Link || 
                node.role === NodeRoles.Inventory)
                continue;

            // For cycle time we are displaying case values also in the material view and are therefore using a different function.
            const value = settings.kpi.selectedKpi !== KpiTypes.CycleTime ? getMainNodeStat(node, settings, session) : getCycleTime(node, settings, session);

            if (value === undefined)
                continue;

            const group: Bar<Node>[] = [{
                value: value,
                data: node,
                label: getShortActivityLabelFromActivityValues(node.activityValues, settings.groupingKey, props.analysisType === AnalysisType.ValueStream),
            }];

            let comparisonValue: number | undefined = undefined;

            // Get comparison value: Planning
            if (isPlanningComparison && actual !== undefined && planned !== undefined) {
                // Find deviation stats corresponding to product.
                // This is only correct because neither the DFG request, nor the deviation request
                // have a limit set. If we were to introduce such a limit, we need to be sure to
                // sort the results by the same property, e.g. the actual duration sum.
                const plannedNode = plannedNodeMap?.get(node.id);
                if (plannedNode)
                    comparisonValue = settings.kpi.selectedKpi !== KpiTypes.CycleTime ? getMainNodeStat(plannedNode, settings, session) : getCycleTime(plannedNode, settings, session);
            }

            // Get comparison value: best case
            if (settings.kpi.comparisons === KpiComparisons.BestProcesses) {
                const statistics = getNodeKpiStatistics(session, settings, node, settings.kpi.selectedKpi);
                comparisonValue = kpiDefinition?.isLessBetter ? statistics?.p25 : statistics?.p75;
            }

            if (settings.kpi.comparisons !== KpiComparisons.None) {
                const hasComparisonValue = isComparisonAllowed && comparisonValue !== undefined && !isNaN(comparisonValue);
                group.push({
                    value: hasComparisonValue ? comparisonValue! : 0,
                    valueLabel: !hasComparisonValue ? i18n.t("common.notAvailableAbbreviated").toString() : undefined,
                    data: node,
                });
            }

            if (isComparisonHighlightingEnabled)
                group[0].barColor = getBarColor(kpiDefinition, value, comparisonValue);

            result.push(group);
        }

        const sortedResults = settings.kpi.selectedKpi === KpiTypes.CycleTime ? sortByPassId(result) : sortBySelectedOption(settings, result);
        if (!sortedResults)
            return [];

        return settings.kpi.sortOrder === SortOrder.Descending ?
            sortedResults.reverse() :
            sortedResults;
    }, [
        settings.kpi.comparisons,
        settings.kpi.sortOrder,
        settings.kpi.sortBy,
        settings.kpi.selectedKpi,
        settings.kpi.statistic,
        settings.graph.objectType,
        settings.quantity,
        actual,
        planned,
        isComparisonHighlightingEnabled,
    ]);
    const unit = getUnit(kpiDefinition?.unit, settings.kpi.statistic);
    const hasData = data.some(e => e.length > 0 && e[0].value !== undefined);
    const headlineKey = getAnalysisTitle(session, settings);
    const downloadAllowed = hasDownloadPermission(session);
    const noDataPlaceholder = (isObjectCentric && settings.kpi.selectedKpi !== KpiTypes.BusyTime) ? "kpi.noDataObjectCentric" : props.noDataPlaceholder;
    return <>
        <div className={className} ref={container}>
            <Spinner isLoading={isLoading} showProjectLoadingSpinner={true} />
            {graph !== undefined && !hasData && !isLoading && <div className="noKpisAvailable">
                {i18n.t("common.noKpisAvailable")}
                {!!noDataPlaceholder && <div>
                    {i18n.t(noDataPlaceholder)}
                </div>}
            </div>}

            {hasData && !isLoading && !!height && !!width && <>
                <GroupGraph
                    horizonalLines={drawLine(horizonalLines, actual, settings, session)}
                    width={width}
                    height={height}
                    title={i18n.t(headlineKey).toString()}
                    padding={{
                        top: 85,
                        left: 60,
                        bottom: 100,
                    }}
                    barPadding={10}
                    minGroupPadding={50}
                    showYAxisLines={true}
                    yAxisLabel={getAxisLabel(settings.kpi.selectedKpi, settings.kpi.statistic, session, settings)}
                    selectedGroupIdx={data.findIndex(d => d[0].data?.id === settings.selection.node?.id)}
                    selectedGroupBarIdx={0}
                    onSelected={(groupIdx, barIdx, data) => {
                        if (settings.selection.node === data)
                            settings.setSelection({});
                        else
                            settings.setSelection({
                                node: data
                            });
                    }}
                    onLabelSelected={(groupIdx) => {
                        const element = data[groupIdx][0].data;
                        if (settings.selection.node === element)
                            settings.setSelection({});
                        else
                            settings.setSelection({
                                node: element
                            });
                    }}
                    showBarValues={true}
                    yAxisUnit={unit}
                    formatterParams={{
                        numDigits: 1,
                    }}
                    valueFormatter={(value) => {
                        return ensureProperCountFormatting(value, unit, session, settings.quantity);
                    }}
                    data={data}
                    containerMode={ContainerModes.Constrained}
                    legend={getLegend(settings, isComparisonHighlightingEnabled)}
                />
                <DownloadFile
                    data={data}
                    planningData={settings.kpi.comparisons === KpiComparisons.Planning}
                    isValueStream={valueStreamGroupingKeys.includes(settings.groupingKey)}
                    template={settings.kpi.selectedKpi === KpiTypes.CycleTime ? TemplateType.NodeCycleTimes : TemplateType.Node}
                    meta={unit}
                    allowed={downloadAllowed}
                    title={i18n.t(headlineKey).toString()} />
            </>}
        </div>
        {props.disableShortcuts ? "" : <Shortcuts handledSelections={[ShortcutContexts.Node]} />}
    </>;

}

export function getRelevantNodes(nodes: Node[], isObjectCentric: boolean, objectType: string | undefined) {
    if (!isObjectCentric || objectType === ALL_OBJECT_INDICATOR)
        return nodes;
    return nodes.filter(n => n.objects?.some(o => o.type === objectType));
}

function sortByPassId(barArray: Bar<Node>[][]) {

    return barArray.sort((a, b) => {
        // In most cases the pass id will be a number. We therefore try to convert the value to a number.
        // This ensures the correct sorting of numbers like [10, 110, 120, 20] to [10, 20, 110, 120].
        const aValue = a[0].data?.activityValues?.passId?.value;
        const bValue = b[0].data?.activityValues?.passId?.value;
        const aName = isNaN(Number(aValue)) ? aValue : Number(aValue);
        const bName = isNaN(Number(bValue)) ? bValue : Number(bValue);

        if (aName === bName)
            return 0;

        if (aName === undefined)
            return -1;

        if (bName === undefined)
            return 1;

        if (aName < bName)
            return -1;

        return 1;
    });
}

function sortBySelectedOption(settings: SettingsType, barArray: Bar<Node>[][]) {

    switch (settings.kpi.sortBy) {
        case SortByType.Kpi:
            return sortByKpi(barArray) as Bar<Node>[][];

        case SortByType.Frequency:
            return sortByFrequency(barArray) as Bar<Node>[][];

        case SortByType.Alphabetical:
            return sortByAlphabetical(barArray) as Bar<Node>[][];

        case SortByType.DeviationFromComparison:
            return sortByDeviation(barArray) as Bar<Node>[][];

        case SortByType.OrderSequences:
            return sortByPassId(barArray);
        default:
            return;
    }
}

export function sortByDeviation(barArray: Bar<Node | Edge>[][]) {
    return barArray.sort((a, b) => {
        // Sort by deviation to the comparison value (stored in a[1] and b[1])
        const deltaA = a.length >= 2 ? a[0].value - a[1].value : a[0].value ?? 0;
        const deltaB = b.length >= 2 ? b[0].value - b[1].value : b[0].value ?? 0;

        return deltaA - deltaB;
    });
}

export function sortByKpi(barArray: Bar<Node | Edge>[][]) {
    return barArray.sort((a, b) => {
        const aValue = a[0].value ?? 0;
        const bValue = b[0].value ?? 0;
        return aValue - bValue;
    });
}

export function sortByFrequency(barArray: Bar<Node | Edge>[][]) {
    return barArray.sort((a, b) => {
        const aValue = a[0].data?.frequencyStatistics?.sum ?? 0;
        const bValue = b[0].data?.frequencyStatistics?.sum ?? 0;
        return aValue - bValue;
    });
}

export function sortByAlphabetical(barArray: Bar<Node | Edge>[][]) {
    return barArray.sort((a, b) => {
        const aLabel = a[0].label ? a[0].label : "";
        const bLabel = b[0].label ? b[0].label : "";
        return aLabel.localeCompare(bLabel);
    });
}
export function ensureProperCountFormatting(value: number | undefined, unit: UnitMetadata | undefined, session: SessionType, baseQuantity?: BaseQuantityType) {
    if (unit === undefined || value === undefined)
        return "";
    // This is not one of my proudest moments as a developer, but we're doing this to avoid
    // overlapping bar value labels by allowing the units to wrap after the usually long "pieces" word.
    return unit.formatter(value, {
        numDigits: 1,
        locale: session.numberFormatLocale,
        baseQuantity
    }).replace(i18n.t("quantities.count") + "/", i18n.t("quantities.count") + " /");
}

export function exportNodeData(data: Bar<Node>[][], settings: SettingsType, session: SessionType, planningData?: boolean) {

    const result = [];

    const activityKeys = groupKeyToActivityKeys(settings.groupingKey, session.project!.eventKeys!);
    type Item = {
        [key: string]: string | number | undefined;
    }

    for (const bar of data) {
        let item: Item = {};

        for (const activityKey of activityKeys) {
            const temporaryItem = {
                [translateActivityKey(activityKey) ?? ""]: bar[0]?.data?.activityValues?.[activityKey]?.value,
            };
            item = Object.assign(item, temporaryItem);
        }

        item[i18n.t("common.actual")] = bar[0]?.value;

        if (planningData)
            item[i18n.t("common.plan")] = bar[1]?.value;

        result.push(item);
    }
    return result;
}

// To draw the green horizontal line we use the value of the selected element in the graph as a reference value. 
function drawLine(horizonalLines: GraphLine[], graph: BaseGraph | undefined, settings: SettingsType, session: SessionType): GraphLine[] {
    if (graph === undefined)
        return horizonalLines;

    const selectedNode = settings.selection.node ? graph?.nodes?.find(n => n.id === settings.selection.node?.id) : undefined;
    const value = selectedNode ? (settings.kpi.selectedKpi !== KpiTypes.CycleTime ? getMainNodeStat(selectedNode, settings, session) : getCycleTime(selectedNode, settings, session)) : undefined;

    if (value !== undefined)
        horizonalLines.push({ ...{ value: value, ...commonSelectionLineProps } });

    return horizonalLines;
}
