import React, { useContext } from "react";
import { CaseDeviationStatisticsSchema, ProductCaseAggregationStatistics, ProductDeviationStatisticsSchema, TimeperiodDeviationStatisticsSchema } from "../../models/ApiTypes";
import i18n from "../../i18n";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";
import { Bar } from "../graph/GroupGraph";
import { Edge, Node } from "../../models/Dfg";
import { exportProductData } from "../product-chart/ProductChart";
import { SettingsContext, SettingsType } from "../../contexts/SettingsContext";
import { Formatter, UnitMetadata, UnitScale } from "../../utils/Formatter";
import { CaseStatistics } from "../../models/Case";
import { exportTimeData } from "../timeperiods-chart/TimeperiodsChart";
import { exportNodeData } from "../kpi-chart/NodeKpiChart";
import { exportEdgeData } from "../kpi-chart/EdgeKpiChart";
import { buildControllerSpotlightId } from "../../utils/Utils";
import { isNumber } from "lodash";
import { SessionContext, SessionContextType } from "../../contexts/SessionContext";
import { Stats } from "../../models/Stats";
import { exportCaseData } from "../../views/process-kpi-chart/CaseProcessKpiChart";
import { exportProductVariationData } from "../../views/process-path/ProductVariations";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { TrayIconElement } from "../tray/TrayElement";
import { exportEdgeVarianceData } from "../kpi-chart/EdgeVarianceChart";
import { exportNodeVarianceData } from "../kpi-chart/NodeVarianceChart";
import { ProductCaseAggregationStatisticsSchema } from "../../models/generated";

export enum TemplateType {
    Product,
    Case,
    Time,
    Node,
    NodeCycleTimes,
    Edge,
    ProductDeviationAverage,
    ProductDeviationSum,
    ProductDeviationCase,
    ProductVariations,
    EdgeVariance,
    NodeVariance,
    NodeVarianceCycleTimes
}

type DataProps<DATA_TYPE> = {
    data: Bar<DATA_TYPE>[][] | Bar<DATA_TYPE>[];
    fileName?: string;
    planningData?: boolean;
    isValueStream?: boolean;
    template: TemplateType;
    meta?: UnitMetadata | undefined;
    header?: string[][];
    title?: string;
    allowed?: boolean | undefined;
}


export function DownloadFile<DATA_TYPE>(props: DataProps<DATA_TYPE>) {

    const settings = useContext(SettingsContext);
    const session = useContext(SessionContext);
    const { trackEvent } = useMatomo();

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const url = new URL(window.location.href);


    const exportToExcel = () => {
        trackEvent({
            category: "Interactions",
            action: "Download",
            name: new URL(window.location.href).pathname,
        });

        const result = exportData(props.data, settings, session, props.planningData, props.template);

        if (!result?.length || !props.allowed)
            return;

        // Determine the maximum value of the exported data
        let maxValue = 0;
        for (const element of result)
            for (const key of Object.keys(element)) {
                const value: string | number | undefined = element[key];
                if (!isNumber(value))
                    continue;

                maxValue = Math.max(maxValue, Math.abs(value));
            }

        const units = props.meta?.getUnits({ baseQuantity: settings.quantity }) ??
            Formatter.defaultUnit.getUnits({ baseQuantity: settings.quantity });

        const unit = units.find(u => u.size === 1) ?? Formatter.getUnit(units, maxValue);

        // Scale exported values
        for (const element of result)
            for (const key of Object.keys(element)) {
                const value: string | number | undefined = element[key];
                if (isNumber(value))
                    element[key] = value / unit!.size;
            }

        const fileName = getFileName(settings, window.location.pathname, props.template);
        const header = getFileHeader(url, props.title, unit!);

        const ws = XLSX.utils.json_to_sheet([header[0]], {
            skipHeader: true
        });

        XLSX.utils.sheet_add_json(ws, [header[1]], {
            skipHeader: true,
            origin: "A2",
        });

        XLSX.utils.sheet_add_json(ws, [header[2]], {
            skipHeader: true,
            origin: "A3",
        });

        XLSX.utils.sheet_add_json(ws, result ?? [], {
            origin: "A6",
        });

        sheetStyling(ws);
        const wb = { Sheets: { "Sheet": ws }, SheetNames: ["Sheet"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(blob, fileName + fileExtension);

    };

    return <TrayIconElement
        icon="radix-download"
        testId="download-excel"
        id="downloadExcel"
        onClick={() => exportToExcel()}
        disabled={!props.allowed}
        title={props.allowed ? "common.downloadToExcel" : "common.downloadNotAllowed"}
    />;
}

function exportData<DATA_TYPE>(data: Bar<DATA_TYPE>[] | Bar<DATA_TYPE>[][], settings: SettingsType, session: SessionContextType, planningData?: boolean, template?: TemplateType) {

    switch (template) {
        case TemplateType.Product:
            return exportProductData(data as Bar<ProductDeviationStatisticsSchema | ProductCaseAggregationStatistics>[][], planningData);

        case TemplateType.Case:
            return exportCaseData(data as Bar<CaseDeviationStatisticsSchema | CaseStatistics>[][], planningData);

        case TemplateType.Time:
            return exportTimeData(data as Bar<TimeperiodDeviationStatisticsSchema>[][], planningData);

        case TemplateType.Node:
        case TemplateType.NodeCycleTimes:
            return exportNodeData(data as Bar<Node>[][], settings, session, planningData);

        case TemplateType.Edge:
            return exportEdgeData(data as Bar<Edge>[][], planningData);

        case TemplateType.ProductVariations:
            return exportProductVariationData(data as Bar<{
                product: ProductCaseAggregationStatisticsSchema,
                stats: Stats,
            }>[]);

        case TemplateType.EdgeVariance:
            return exportEdgeVarianceData(data as Bar<{
                edge: Edge,
                stats: Stats,
            }>[]);

        case TemplateType.NodeVariance:
        case TemplateType.NodeVarianceCycleTimes:
            return exportNodeVarianceData(data as Bar<{
                node: Node,
                stats: Stats,
            }>[], settings, session);
    }
}

function sheetStyling(ws: XLSX.WorkSheet) {
    const boldStyle = {
        font: {
            bold: true
        }
    };


    ws["B1"].s = boldStyle;
    // We just format all headers bold as long there is no empty cell.
    let i = 0;
    let cellId = `${String.fromCharCode(65 + i)}6`; // A6, B6, C6, ...
    
    while (ws[cellId] !== undefined) {
        ws[cellId].s = boldStyle;
        i++;
        cellId = `${String.fromCharCode(65 + i)}6`;
    }

}

function getFileName(settings: SettingsType, pathname: string, template: TemplateType) {


    const downloadDate = new Date().toJSON().slice(0, 10);
    const fileName = buildControllerSpotlightId(pathname, [[TemplateType.Edge, TemplateType.NodeCycleTimes, TemplateType.Node].includes(template) ? "" : settings.kpi.aggregation.toString()]);
    const formattedFileName = fileName?.replace("-Controller", "");
    return (downloadDate + "_" + formattedFileName);

}

function getFileHeader(url: URL, title: string | undefined, unit: UnitScale) {
    const headerDetails = [
        [i18n.t("common.title"), title],
        [i18n.t("common.unit"), i18n.t(unit?.name === undefined || unit?.name === "" ? "-" : unit?.name)],
        [i18n.t("common.fromUrl"), url.href]
    ];
    return headerDetails;

}

export default DownloadFile;